import axios from "axios"
import * as React from "react"
import * as Illust from "../../assets/illust"
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { getUserFirestore, signIn } from "../../handlers"
import { UserDBT, UserT } from "../../types"
// import mailTemplate from '../html-emails/membership-payment'
// import { sendMail } from './sendMail'
// import { slackInvitation } from './slack-invitation'
import { Log } from "../../utils"
import { loadFireStore } from "../firebase"

export const subsrcibe = async (
  user,
  values,
  membership,
  stripe,
  setFormLoading,
  setModal,
  setAlert,
  setUser
) => {
  const email = values.email || user.email
  Log.info(`subsrcibe/user`, user)

  const { token } = await stripe.createToken({ name: email })
  if (token) {
    try {
      setFormLoading(true)
      if (values.email) {
        // prevent already member subscribe
        const userDB: UserDBT = await getUserFirestore(user.uid)
        if (userDB && userDB.subscription) {
          setModal("")
          setAlert({
            show: true,
            kind: "negative",
            title: "error",
            message: "Already has a Subscription, Sign In First",
            body: <Illust.Hammer width={100} />,
          })
          setFormLoading(false)
        } else {
          // start subscribe
          await startSubscribe(user.uid, email, token, membership)
          signIn({ email }, setFormLoading, setModal, setAlert) // sign in-up with subscribe
        }
      } else if (user.email) {
        // start subscribe & store it
        const userData: UserT = {
          ...user,
          ...(await startSubscribe(user.uid, email, token, membership)),
        }
        setUser(userData)
        setModal("")
        setAlert({
          show: true,
          kind: "positive",
          title: "Thanks",
          message: "Welcome",
          body: <Illust.Heart width={100} />,
        })
        setFormLoading(false)
      }
    } catch (error) {
      console.error("error - subscribe", error)
      setModal("")
      setAlert({
        show: true,
        kind: "negative",
        title: "error",
        message: error.message,
        body: <Illust.Hammer width={100} />,
      })
      setFormLoading(false)
    }
  }
}

async function startSubscribe(userId, email, token, membership) {
  // start subscribe
  const { data: customer } = await axios
    .post(STRIPE_SERVERLESS_API_BASE + "/customers/create", {
      source: token.id,
      email,
    })
    .catch(error => {
      throw Error(error.response.data.message)
    })
  const { data: subscription } = await axios
    .post(STRIPE_SERVERLESS_API_BASE + "/subscriptions/create", {
      customer: customer.id,
      plan: membership.plan,
    })
    .catch(error => {
      throw Error(error.response.data.message)
    })
  // update userDB
  const subscriptionData = {
    customer: customer.id,
    card: token.card,
    subscription: {
      id: subscription.id,
    },
  }
  let doc = await loadFireStore("user", userId)
  await doc.update(subscriptionData)

  // await slackInvitation(values.email)
  // await sendMail(
  //   intl.formatMessage({
  //     id: 'email.member.subject',
  //   }),
  //   mailTemplate(originID),
  //   [values.email]
  // )
  return {
    ...subscriptionData,
    subscription,
    member: subscription.status === "active",
  }
}
