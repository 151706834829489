import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"

export function SectionLabel({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return (
    <>
      <System.LabelSmall
        overrides={{
          Block: {
            color: theme.colors.primary,
            fontWeight: 800,
          },
        }}
      >
        {children}
      </System.LabelSmall>
      <System.Block height={theme.sizing.scale400} />
    </>
  )
}

SectionLabel.defaultProps = {}
