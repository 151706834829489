import * as React from "react"
import { useCurrentStep } from "../../../../hooks"
import { useRef } from "react"
import { useTheme } from "@harborschool/lighthouse"
import { SectionDetailStepsWrap } from "./styled-components"
import { SectionDetailStepsContext } from "../../../../store"
import {
  SECTION_DETAIL_STEPS_CLASSNAME,
  FOCUS_POINT,
} from "../../../../constants"

export function SectionDetailSteps({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const ref = useRef(null)
  const [currentStep] = useCurrentStep(
    ref,
    SECTION_DETAIL_STEPS_CLASSNAME,
    FOCUS_POINT
  )
  const sectionDetailStepsProps = { $currentStep: currentStep }

  return (
    <SectionDetailStepsContext.Provider value={sectionDetailStepsProps}>
      <SectionDetailStepsWrap ref={ref} {...sharedProps}>
        <div className={SECTION_DETAIL_STEPS_CLASSNAME}>
          {React.Children.map(children, (child, index) => {
            const active = currentStep == index
            const newProps = {
              active,
              isFirst: index === 0,
              isLast: index === children.length - 1,
            }
            return React.cloneElement(child, { ...newProps })
          })}
        </div>
      </SectionDetailStepsWrap>
    </SectionDetailStepsContext.Provider>
  )
}

SectionDetailSteps.defaultProps = {}
