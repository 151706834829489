import * as React from "react"
import * as System from "@harborschool/lighthouse"

export function Lock({ width, color, align }) {
  return (
    <System.Icon viewBox="0 0 434 477" width={width} align={align}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.998 43.3087C193.977 43.3087 171.9 52.4345 155.621 68.6784C139.343 84.9223 130.198 106.954 130.198 129.926V194.889H303.798V129.926C303.798 106.954 294.653 84.9223 278.375 68.6784C262.097 52.4345 240.019 43.3087 216.998 43.3087ZM86.7983 129.926V194.889H65.1C29.1463 194.889 0 223.974 0 259.852V411.433C0 447.311 29.1463 476.396 65.1 476.396H368.9C404.854 476.396 434 447.311 434 411.433V259.852C434 223.974 404.854 194.889 368.9 194.889H347.198V129.926C347.198 95.4676 333.481 62.4204 309.064 38.0545C284.646 13.6886 251.529 0 216.998 0C182.467 0 149.35 13.6886 124.933 38.0545C100.516 62.4204 86.7983 95.4676 86.7983 129.926ZM174.663 277.523C174.663 284.169 180.068 289.558 186.732 289.558C193.399 289.558 198.803 284.169 198.803 277.523C198.803 270.875 193.399 265.487 186.732 265.487C180.068 265.487 174.663 270.875 174.663 277.523ZM156.498 344.139C156.663 377.305 183.586 404.15 216.848 404.314C232.938 404.443 248.412 398.16 259.835 386.861C271.256 375.562 277.681 360.181 277.681 344.139H260.964C260.09 367.653 240.718 386.265 217.12 386.265C193.521 386.265 174.15 367.653 173.276 344.139H156.498ZM229.945 277.522C229.945 271.074 235.187 265.848 241.654 265.848C248.119 265.848 253.361 271.074 253.361 277.522C253.361 283.969 248.119 289.196 241.654 289.196C235.187 289.196 229.945 283.969 229.945 277.522Z"
        fill={color}
      />
    </System.Icon>
  )
}

Lock.defaultProps = {
  width: 434,
  color: "black",
  align: "center",
}
