import { Log } from "./log"

export const isClient = typeof window !== "undefined"

export function Err(message) {
  this.message = message
}

Err.prototype = new Error()

const guideOn = false
export const sizeBg = guideOn ? "#c4deb7" : "transparent"
export const guideBg = guideOn ? "#b2b2b2" : "transparent"

export const timeStringToSeconds = hms => {
  const a = hms.includes(";") ? hms.split(";") : hms.split(":") // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds =
    // +a[0] * 60 * 60 + // this line for hours
    +a[1] * 60 + +a[2]
  return seconds
}

export const timeStampToDate = timeStamp => {
  var a = new Date(timeStamp * 1000)
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  var sec = a.getSeconds()
  var time = date + " " + month + " " + year
  // + " " + hour + ":" + min + ":" + sec
  return time
}

export const timeGap = timeStamp => {
  const now = new Date().getTime()
  const gap = (now - timeStamp) / 1000 / 60
  if (gap < 60) return Math.round(gap) + "min"
  else if (60 < gap && gap < 60 * 24) return Math.round(gap / 60) + "h"
  else return Math.round(gap / (60 * 24)) + "d"
}

export function stringKnife(str, range, remove = false) {
  if (typeof range == "number") range = [range, undefined]
  const [start, end] = range
  const sliced = str.slice(start, end)
  if ((!remove && !end && start > 0) || (remove && start < 0))
    return str.replace(sliced, "")
  return sliced
}

export const isEmpty = obj =>
  obj === null ||
  obj === undefined ||
  (Object.keys(obj).length === 0 && obj.constructor === Object)

export function oneDecimalPlace(price) {
  return (Math.round(price * 10) / 10).toFixed(1)
}

export function removeValueFromArray(value, arr) {
  let newArr = []
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item !== value) newArr = [...newArr, item]
  }
  return newArr
}
