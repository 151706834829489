import * as React from "react"
import ReactPlayer from "react-player"
import { useState } from "react"
import { SectionDetailExplorerWrap, StickyWrap } from "./styled-components"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { useVideoUpdate } from "../../../../hooks/post-contents"
import { isClient } from "../../../../utils"

function Sticky({ videoRef, currentVideoTime, children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const percent = videoRef.current
    ? (currentVideoTime / videoRef.current.getDuration()) * 100
    : 0

  return (
    <StickyWrap {...sharedProps}>
      {children}
      <System.Block
        backgroundColor="primary"
        height="scale300"
        position="absolute"
        bottom="0"
        width={`${percent}%`}
        overrides={{
          Block: {
            borderRadius: "1000px",
          },
        }}
      />
    </StickyWrap>
  )
}

export function SectionDetailVideo({ src }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [ref, currentVideoTime] = useVideoUpdate()
  const [mediaReady, setMediaReady] = useState(false)

  return (
    <SectionDetailExplorerWrap {...sharedProps}>
      {isClient && (
        <Sticky videoRef={ref} currentVideoTime={currentVideoTime}>
          <ReactPlayer
            ref={ref}
            url={src}
            playsinline
            onReady={() => setMediaReady(true)}
            width="100%"
            height="initial"
            display="block"
            style={{
              display: "flex", // fit Sticky to inner video tag height
            }}
          />
        </Sticky>
      )}
    </SectionDetailExplorerWrap>
  )
}

SectionDetailVideo.defaultProps = {}
