import { loadFireStore } from "../../handlers"

export const getUserDocumentByEmail = async email => {
  try {
    const collection = await loadFireStore("user", null)
    const snapshots = await collection.where("email", "==", email).get()
    const snapshot = snapshots.size > 0 && snapshots.docs[0]
    const data = snapshot.data()

    // get progress of courses
    const progressCollection = await snapshot.ref.collection("progress")
    const progressSnapshot = await progressCollection.get()
    const progressDatas = {}
    progressSnapshot.forEach(doc => {
      progressDatas[doc.id] = { ...doc.data() }
    })

    return {
      uid: snapshot.id,
      ...data,
      progress: { ...progressDatas },
    }
  } catch (error) {
    console.error("error - getUserDocumentByEmail", error)
  }
}
