import * as React from "react"
import { Grid } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"

export function SectionDetail({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return <Grid {...sharedProps}>{children}</Grid>
}

SectionDetail.defaultProps = {}
