import * as React from "react"
import { SectionSummaryDescriptionWrap } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"

export function SectionSummaryDescription({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return (
    <SectionSummaryDescriptionWrap {...sharedProps}>
      {children}
    </SectionSummaryDescriptionWrap>
  )
}

SectionSummaryDescription.defaultProps = {}
