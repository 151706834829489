import { loadFireStore } from "../../handlers"

export const getUserFirestore = async userId => {
  try {
    let doc = await loadFireStore("user", userId)
    const collection = await doc.collection("progress")
    // get progress of courses
    const snapshot = await collection.get()
    const progressDatas = {}
    snapshot.forEach(doc => {
      progressDatas[doc.id] = { ...doc.data() }
    })
    // @ts-ignore
    return {
      ...(await (await doc.get()).data()),
      progress: { ...progressDatas },
    }
  } catch (error) {
    console.error("error - getUserFirestore", error)
  }
}
