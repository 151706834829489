import { styled } from "styletron-react"
import { guideBg } from "../../../../utils"

/**
 * SectionSummary
 */
export const Grid = styled("div", ({ $theme }) => {
  return {
    display: "grid",
    gridTemplateColumns: "5fr 7fr",
    columnGap: $theme.sizing.scale700,
    [$theme.mediaQuery.large]: {
      gridTemplateColumns: "1fr",
    },
  }
})

/**
 * SectionSummaryDescription
 */
export const SectionSummaryDescriptionWrap = styled("div", ({ $theme }) => {
  return {
    background: guideBg,
    padding: `0px ${$theme.sizing.scale1200}`,
    position: "relative",
    [$theme.mediaQuery.large]: {
      padding: `${$theme.sizing.scale1200} ${$theme.sizing.scale800}`,
    },
  }
})
