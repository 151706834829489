import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { loadFireStore } from "../../handlers"
import { loadingState, userState } from "./../../store"

export const useLastPost = currentPost => {
  const loading = useRecoilValue(loadingState)
  const user = useRecoilValue(userState)

  useEffect(() => {
    async function update() {
      let doc = await loadFireStore("user", user.uid)
      const { course, postId } = getCourseAndPostId(currentPost)
      const collection = await doc.collection("progress")
      await collection.doc(course).set({ last: postId })
    }
    if (loading === "loaded" && user.uid) update()
  }, [loading, user])
}

function getCourseAndPostId(currentPost) {
  const target = currentPost.split("/")
  const course = target[1]
  let postId = ""
  for (let i = 2; i < target.length - 1; i++) {
    const element = target[i]
    postId += "/"
    postId += element
  }
  return { course, postId }
}
