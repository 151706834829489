import { veInfoState, veTOCState } from "../../store/store"
import { useEffect, useState } from "react"
import { Log } from "../../utils"
import { transform, useViewportScroll } from "framer-motion"
import { useSetRecoilState, useRecoilState } from "recoil"
import { veState, veSectionState } from "../../store"
import { veScrollRevealDelay, SCROLL_START } from "../../constants"

export function useVeUpdateByScroll({ markersMap, contentsMap }) {
  const setVe = useSetRecoilState(veState)
  const setVeInfo = useSetRecoilState(veInfoState)
  const setVeSection = useSetRecoilState(veSectionState)
  const setVeTOC = useSetRecoilState(veTOCState)
  let tempCurrentSectionNum = 0 // to safely update currentSection state
  let tempShowTOC = false
  let tempAutoPlayVideo = 0 // to safely update currentSection state

  let contentMapArray, markersMapArray, sectionMap, videoDuration

  Log.info(`useVeUpdateByScroll/videoDuration`, videoDuration)

  useEffect(() => {
    // console.group("useVeUpdateByScroll - useEffect")

    if (
      // sectionSummaryHeight !== 0 &&
      markersMap.sections.length &&
      contentsMap.heights.length &&
      markersMap.sections.length === contentsMap.heights.length && // do scroll tracking after other states are ready
      !contentMapArray &&
      !markersMapArray &&
      !sectionMap &&
      !videoDuration
    ) {
      contentMapArray = generateContentMapArray(contentsMap)
      markersMapArray = generateMarkersMapArray(markersMap)
      Log.info(`VideoExplorer/contentMapArray`, contentMapArray)
      Log.info(`VideoExplorer/markersMapArray`, markersMapArray)
      sectionMap = makeSectionMapfromMarkers(contentsMap)
      Log.info(`TableOfContents/sectionMap`, sectionMap)

      // store video info
      videoDuration = markersMapArray[markersMapArray.length - 1]
      Log.info(`useVeUpdateByScroll/setVeInfo!!`, videoDuration)
      setVeInfo({
        videoDuration,
      })
    }

    function handleScroll() {
      if (contentMapArray && markersMapArray && sectionMap && videoDuration) {
        // onReady to Explore the video
        var doc = document.documentElement
        var y = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const newVideoTime: number = transform(
          y,
          contentMapArray,
          markersMapArray
        )

        // store video state
        setVe({
          currentVideoTime: newVideoTime,
        })
        // updateCurrentSection (animating TableOfContents)
        if (sectionMap.sections[tempCurrentSectionNum]) {
          if (
            sectionMap.sections[tempCurrentSectionNum + 1] &&
            y > sectionMap.sections[tempCurrentSectionNum + 1].start
          ) {
            //[Case 3] Section 2 Summary ~ (Top to Bottom)
            setVeSection({
              currentSection: ++tempCurrentSectionNum,
            })
          } else if (
            y < sectionMap.sections[tempCurrentSectionNum].start &&
            tempCurrentSectionNum >= 1
          ) {
            //[Case 4] Post End ~ (Bottom to Top)
            setVeSection({
              currentSection: --tempCurrentSectionNum,
            })
          } else if (y < sectionMap.sections[0].start) {
            //[Case 1] Post Summary ~ Section 1 Summary
            setVeSection({
              currentSection: -1,
            })
          } else if (
            sectionMap.sections[0].end > y &&
            y > sectionMap.sections[0].start
          ) {
            //[Case 2] Section 1 Start ~ Section 1 End
            tempCurrentSectionNum = 0
            setVeSection({
              currentSection: 0,
            })
          }

          // Handle Table of Contents
          if (!tempShowTOC && tempCurrentSectionNum === -1) {
            tempShowTOC = true
            setVeTOC({ show: true })
          } else if (tempShowTOC && newVideoTime === videoDuration) {
            tempShowTOC = false
            setVeTOC({ show: false })
          } else if (!tempShowTOC && newVideoTime < videoDuration) {
            tempShowTOC = true
            setVeTOC({ show: true })
          }

          // Handle AutoPlayVideo
          // if (
          //   sectionMap.sections[tempAutoPlayVideo + 1] &&
          //   y + sectionSummaryHeight * 0.5 >
          //     sectionMap.sections[tempAutoPlayVideo + 1].start
          // )
          //   setAutoPlayVideo(++tempAutoPlayVideo)
          // else if (
          //   y + sectionSummaryHeight * 0.5 <
          //     sectionMap.sections[tempAutoPlayVideo].start &&
          //   tempAutoPlayVideo >= 1
          // )
          //   setAutoPlayVideo(--tempAutoPlayVideo)
          // // Handle Appear of the first Video Explorer!
          // else if (y + sectionSummaryHeight * 0.5 < sectionMap.sections[0].start)
          //   setAutoPlayVideo(-1)
          // else if (
          //   sectionMap.sections[0].end > y + sectionSummaryHeight * 0.5 &&
          //   y + sectionSummaryHeight * 0.5 > sectionMap.sections[0].start
          // ) {
          //   tempAutoPlayVideo = 0
          //   setAutoPlayVideo(0)
          // } // Handle End of Scroll
          // else if (newVideoTime === videoDuration) setAutoPlayVideo(-1)
          // else setAutoPlayVideo(tempAutoPlayVideo)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    // console.groupEnd()
    // return window.removeEventListener("scroll", handleScroll)
    return () => {
      // contentMapArray = null
      // markersMapArray = null
      // sectionMap = null
      // videoDuration = null
      window.removeEventListener("scroll", handleScroll)
    }
  }, [markersMap, contentsMap])
}

function generateMarkersMapArray(markersMap) {
  let result = [0, 0] // init with heading section
  for (let i = 0; i < markersMap.sections.length; i++) {
    const section = markersMap.sections[i]
    let currentSteps = []
    for (let i = 0; i < markersMap.steps.length; i++) {
      const step = markersMap.steps[i]
      if (step.start >= section.start)
        currentSteps = unique([...currentSteps, ...Object.values(step)]) // add step points (ex - [0, 10, 19] or [19, 24, 25])
      if (step.end === section.end) break
    }
    currentSteps.unshift(section.start)
    // if (isTablet()) currentSteps.unshift(section.start) // for PostContents that expanded as a row
    result = [...result, ...currentSteps]
  }
  return result
}
function unique(a) {
  for (let i = 0; i < a.length; ++i)
    for (let j = i + 1; j < a.length; ++j) if (a[i] === a[j]) a.splice(j--, 1)
  return a
}

function makeSectionMapfromMarkers({
  postSummaryHeight,
  heights,
  sectionSummaryHeights,
  stepHeights,
}) {
  const sections = []
  const steps = []
  const sectionHeights = heights
  // make sections
  for (let i = 0; i < sectionHeights.length; i++) {
    const element = sectionHeights[i]
    const currentStart =
      (sections[i - 1] ? sections[i - 1].end : postSummaryHeight) +
      sectionSummaryHeights[i]
    sections[i] = {
      start: currentStart,
      end: currentStart + element - sectionSummaryHeights[i],
    }
  }
  const delayedSections = []
  for (let i = 0; i < sections.length; i++) {
    const element = sections[i]
    delayedSections[i] = {
      start: element.start - veScrollRevealDelay,
      end: element.end - veScrollRevealDelay,
    }
  }
  // make steps
  return { sections: delayedSections, steps }
}

function generateContentMapArray({
  headerHeight,
  postSummaryHeight,
  sectionSummaryHeights,
  stepHeights,
}) {
  const result = [0, postSummaryHeight + headerHeight] // init with heading section
  for (let i = 0; i < stepHeights.length; i++) {
    const sectionStepHeights = stepHeights[i]
    result.push(result[result.length - 1])
    result.push(sectionSummaryHeights[i] + result[result.length - 1])
    // if (isTablet()) result.push(450 + result[result.length - 1])
    for (let i = 0; i < sectionStepHeights.length; i++) {
      const stepHeight = sectionStepHeights[i]
      result.push(stepHeight + result[result.length - 1])
    }
  }
  // make delay here
  const delayedResult = []
  for (let i = 0; i < result.length; i++) {
    const element = result[i] - SCROLL_START
    delayedResult[i] = element
  }
  return delayedResult
}
