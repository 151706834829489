import { motion } from "framer-motion"
import * as React from "react"

export function InstaClone({ $size, $animate }) {
  const motionProps = $animate && {
    animate: {
      y: [0, -60, 20, 0],
      rotate: [0, -90, -180, 0],
    },
    transition: {
      times: [0, 0.4, 0.8, 1],
      duration: 3,
      repeat: Infinity,
      repeatDelay: 1,
    },
  }
  return (
    <svg
      width={$size}
      viewBox="0 0 1219 1213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        overflow: "visible",
      }}
    >
      <motion.g {...motionProps}>
        <path
          d="M466.71 32.5081C471.453 14.8051 489.65 4.29945 507.353 9.04294L946.492 126.71C964.195 131.453 974.701 149.65 969.957 167.353L852.29 606.492C847.547 624.195 829.35 634.701 811.647 629.957L372.508 512.29C354.805 507.547 344.299 489.35 349.043 471.647L466.71 32.5081Z"
          fill="#F4F4F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M943.056 139.532L503.917 21.8645C493.295 19.0184 482.377 25.3219 479.531 35.9436L361.864 475.083C359.018 485.705 365.322 496.623 375.943 499.469L815.083 617.136C825.705 619.982 836.622 613.678 839.468 603.057L957.136 163.917C959.982 153.296 953.678 142.378 943.056 139.532ZM507.353 9.04294C489.65 4.29945 471.453 14.8051 466.71 32.5081L349.043 471.647C344.299 489.35 354.805 507.547 372.508 512.29L811.647 629.957C829.35 634.701 847.547 624.195 852.29 606.492L969.957 167.353C974.701 149.65 964.195 131.453 946.492 126.71L507.353 9.04294Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M554.021 309.274C538.96 365.48 572.316 423.254 628.522 438.315C684.729 453.375 742.503 420.019 757.563 363.813C772.624 307.606 739.268 249.832 683.061 234.772C626.855 219.711 569.081 253.067 554.021 309.274ZM686.497 221.95C623.209 204.992 558.157 242.55 541.199 305.838C524.241 369.126 561.799 434.178 625.087 451.136C688.375 468.094 753.427 430.536 770.385 367.248C787.343 303.96 749.785 238.908 686.497 221.95Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M556.744 87.5522L523.088 78.534C521.317 78.0596 519.498 79.1102 519.024 80.8805L510.005 114.537C509.531 116.307 510.581 118.127 512.352 118.601L546.008 127.62C547.779 128.094 549.598 127.043 550.073 125.273L559.091 91.6165C559.565 89.8462 558.515 88.0266 556.744 87.5522ZM526.523 65.7124C517.672 63.3406 508.574 68.5935 506.202 77.445L497.184 111.102C494.812 119.953 500.065 129.051 508.916 131.423L542.573 140.441C551.424 142.813 560.523 137.56 562.894 128.709L571.913 95.0521C574.284 86.2006 569.031 77.1024 560.18 74.7307L526.523 65.7124Z"
          fill="black"
        />
        <path
          d="M843.711 183.341C844.423 180.686 847.152 179.11 849.808 179.822L905.902 194.852C908.558 195.564 910.134 198.293 909.422 200.948L907.704 207.359C906.993 210.015 904.263 211.59 901.608 210.879L845.513 195.849C842.858 195.137 841.282 192.408 841.994 189.752L843.711 183.341Z"
          fill="black"
        />
      </motion.g>
      <path
        d="M84.3675 531.427C325.679 532.265 899.348 530.821 1134.14 531.427C1155.56 531.476 1176.09 540.009 1191.24 555.159C1206.39 570.309 1214.93 590.842 1214.98 612.268C1218.15 621.586 1165.45 1126.2 1167.17 1135.29C1165.26 1155.34 1155.96 1173.96 1141.08 1187.54C1126.2 1201.11 1106.8 1208.66 1086.66 1208.72C857.32 1207.88 350.467 1209.28 125.65 1208.72C105.328 1208.62 85.7814 1200.91 70.8616 1187.11C55.9418 1173.31 46.7303 1154.42 45.0419 1134.17L3.80599 618.744C-1.133 573.361 38.7051 530.308 84.3675 531.427Z"
        fill="white"
      />
      <path
        d="M274.426 1212.36C217.674 1212.36 167.259 1212.36 125.65 1212.36C104.493 1212.23 84.1502 1204.19 68.6272 1189.81C53.1043 1175.44 43.5266 1155.77 41.7804 1134.68H42.1532C41.5008 1131.47 40.9883 1125.13 40.0098 1112.65L0.311506 618.979C-0.701885 607.372 0.763939 595.683 4.61177 584.686C8.4596 573.688 14.6015 563.635 22.6302 555.192C30.4145 546.537 39.9468 539.632 50.5972 534.933C61.2475 530.234 72.7737 527.849 84.4142 527.934C212.828 528.4 435.409 528.214 650.674 527.934C842.782 527.934 1024.27 527.608 1134.14 527.934C1145.22 527.913 1156.2 530.098 1166.43 534.362C1176.66 538.626 1185.94 544.883 1193.73 552.769C1201.63 560.53 1207.9 569.801 1212.15 580.03C1216.4 590.26 1218.55 601.239 1218.47 612.316C1219.31 623.732 1211.62 706.064 1190.84 917.881C1180.68 1021.6 1170.2 1128.81 1170.67 1134.82V1135.57C1168.63 1156.45 1158.93 1175.84 1143.44 1189.99C1127.96 1204.15 1107.78 1212.06 1086.8 1212.22C964.906 1211.75 764.038 1211.94 569.6 1212.22C463.971 1212.26 361.883 1212.36 274.426 1212.36ZM125.603 1205.23C230.953 1205.51 395.431 1205.23 569.6 1205.23C763.852 1205.23 964.72 1204.86 1086.8 1205.23H1087.45C1106.53 1204.95 1124.84 1197.68 1138.92 1184.8C1153 1171.92 1161.86 1154.32 1163.82 1135.34C1163.82 1126.58 1170.01 1060.32 1184.04 917.369C1196.9 785.787 1212.97 622.008 1211.72 613.248V612.689V612.409C1211.7 591.854 1203.53 572.146 1188.99 557.616C1174.45 543.085 1154.74 534.923 1134.18 534.923C1024.27 534.644 842.782 534.923 650.674 534.923C435.362 534.923 212.688 535.296 84.3676 534.923C73.693 534.817 63.1191 536.995 53.3552 541.31C43.5912 545.626 34.8629 551.979 27.7555 559.944C20.384 567.668 14.7428 576.874 11.2077 586.949C7.67266 597.024 6.32485 607.737 7.25405 618.373C7.6734 623.592 48.4434 1132.68 48.5366 1134.03C50.1036 1153.41 58.8987 1171.48 73.1755 1184.67C87.4523 1197.86 106.167 1205.2 125.603 1205.23Z"
        fill="black"
      />
    </svg>
  )
}

InstaClone.defaultProps = {
  $size: 200,
  $animate: false,
}
