import * as React from "react"
import * as Illust from "../../assets/illust"
import axios from "axios"
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { Log } from "../../utils"

export const updateSubscription = async (
  user,
  data,
  setFormLoading,
  setModal,
  setAlert,
  setUser
) => {
  try {
    setFormLoading(true)
    const updated = await axios
      .post(STRIPE_SERVERLESS_API_BASE + "/subscriptions/update", {
        id: user.subscription.id,
        data,
      })
      .catch(error => {
        throw Error(error.response.data.message)
      })
    Log.info(`updateSubscription/updated`, updated)
    setUser({
      ...user,
      subscription: updated.data,
    })
    setModal("")
    setAlert({
      show: true,
      kind: "positive",
      title: "Updated",
      message: "",
      body: <Illust.Done width={100} />,
    })
    setFormLoading(false)
  } catch (error) {
    console.error("error", error)
    setModal("")
    setAlert({
      show: true,
      kind: "negative",
      title: "error",
      message: error.message,
      body: <Illust.Hammer width={100} />,
    })
    setFormLoading(false)
  }
}
