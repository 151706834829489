import { useRef, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { veState } from "../../store"

export function useVideoUpdate() {
  const ref: any = useRef(null)
  const { currentVideoTime } = useRecoilValue(veState) // it re-renders with same values (like 0), though useState don't

  useEffect(() => {
    ref.current.seekTo &&
      currentVideoTime >= 1 &&
      ref.current.seekTo(currentVideoTime)
  }, [currentVideoTime])

  return [ref, currentVideoTime]
}
