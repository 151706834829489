import { MEMBERSHIP } from "./../constants"
import { UserT, LoadingT, MembershipT } from "./../types"
import { atom, RecoilState } from "recoil"
import { AlertPropsT } from "@harborschool/lighthouse"

export const modalState = atom({
  key: "modal",
  default: "",
})

export const loadingState: RecoilState<LoadingT> = atom({
  key: "loading",
  default: "before",
})

export const alertState: RecoilState<AlertPropsT> = atom({
  key: "alert",
  default: {
    show: false,
    kind: "positive",
    title: "",
    message: "",
    body: null,
  },
})

export const selectState = atom({
  key: "select",
  default: "",
})

export const mobileMenuState = atom({
  key: "mobileMenu",
  default: false,
})

/**
 * Post Layout
 */
export const veFramesState = atom({
  key: "veFrames",
  default: [],
})
export const veInfoState = atom({
  key: "veInfo",
  default: {
    videoDuration: 0,
  },
})
export const veState = atom({
  key: "ve",
  default: {
    currentVideoTime: 0,
  },
})

export const veSectionState = atom({
  key: "veSection",
  default: {
    currentSection: -1,
  },
})

export const veTOCState = atom({
  key: "veTOC",
  default: {
    show: false,
  },
})

/**
 * User
 */
export const userState: RecoilState<UserT> = atom({
  key: "user",
  default: {
    email: "",
    name: "",
  },
})

/**
 * Membership
 */
export const membershipState: RecoilState<MembershipT> = atom({
  key: "membership",
  default: MEMBERSHIP.yearly,
})

/**
 * Admin (Temp)
 */
export const constructionState = atom({
  key: "construction",
  default: true,
})
