/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

console.log("process.env.PRODUCTION", process.env.PRODUCTION)

if (process.env.PRODUCTION !== "true") {
  localStorage.setItem("debug", "harbor.school:*")
}
