import { useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { userState, loadingState, alertState } from "../../store"
import { navigate } from "../../../plugins/gatsby-plugin-intl-with-root"

export const useUserWithGuard = () => {
  const loading = useRecoilValue(loadingState)
  const user = useRecoilValue(userState)
  const setAlert = useSetRecoilState(alertState)

  useEffect(() => {
    if (loading === "loaded" && !user.email) {
      setAlert({
        show: true,
        kind: "negative",
        title: "Signed out",
        message: "See you soon!",
      })
      navigate("/")
    }
  }, [loading, user])

  return user
}
