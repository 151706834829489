import * as React from "react"
import { CopyTabWrap } from "./styled-components"
import * as System from "@harborschool/lighthouse"
import { useClipboard } from "../../hooks"

export function CopyTab({ codeString }) {
  const {
    hasCopied: baseCodeStringHasCopied,
    onCopy: baseCodeStringOnCopy,
  } = useClipboard(codeString)

  return (
    <CopyTabWrap onClick={baseCodeStringOnCopy}>
      <System.LabelXSmall color="contentInversePrimary">
        {baseCodeStringHasCopied ? "Copied" : "Copy"}
      </System.LabelXSmall>
    </CopyTabWrap>
  )
}

CopyTab.defaultProps = {}
