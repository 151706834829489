import { loadFireStore } from "../../handlers"

export const updateNotificationQue = async ({ userId, notifications }) => {
  try {
    const doc = await loadFireStore("user", userId)
    await doc.update({ notifications })
  } catch (error) {
    console.error("error - updateNotificationQue", error)
    alert("Error (updateNotificationQue)")
  }
}
