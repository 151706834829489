import { useState, useEffect } from "react"

export function useCurrentStep(ref, targetClassName, focusPoint) {
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    const scroller = ref.current.querySelector(`.${targetClassName}`)
    function onScroll() {
      const newCurrentStep = getCurrentStep(scroller, focusPoint)
      setCurrentStep(newCurrentStep)
    }
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [ref])
  return [currentStep]
}

function getCurrentStep(scroller, focusPoint) {
  const children = scroller.childNodes
  const middle = window.innerHeight * focusPoint
  if (children.length) {
    let prevBottom = children[0].getBoundingClientRect().bottom
    for (let i = 1; i < children.length; i++) {
      const { top, bottom } = children[i].getBoundingClientRect()
      const breakpoint = (prevBottom + top) / 2
      if (middle < breakpoint) {
        return i - 1
      }
      prevBottom = bottom
    }
  }

  return children.length - 1
}
