import * as React from "react"
import { styled } from "styletron-react"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"

export function DescriptionOnly({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return (
    <System.Block
      overrides={{
        Block: {
          [theme.mediaQuery.large]: {
            padding: `0px ${theme.sizing.scale800}`,
          },
        },
      }}
    >
      {children}
    </System.Block>
  )
}

DescriptionOnly.defaultProps = {}
