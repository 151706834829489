import * as React from "react"
import * as System from "@harborschool/lighthouse"

export function Bell({ width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
      style={{
        display: "block",
      }}
    >
      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9m-4.27 13a2 2 0 0 1-3.46 0"></path>
    </svg>
  )
}

Bell.defaultProps = {
  width: 20,
}
