import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"

export function Hammer({ width }) {
  const theme = useTheme()

  return (
    <System.Icon viewBox="0 0 414 301" width={width}>
      <path
        d="M226.953 8.02582L169.873 63.9414L178.809 72.8835L169.741 81.8256L160.804 72.7509L141.335 91.834L196.278 147.882L215.751 128.932L206.281 119.857L215.35 110.918L224.286 119.992L320.705 25.5094L304.969 8.82732L226.953 8.02582Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M248.788 115.408L287.676 77.3265L390.371 182.34L351.482 220.424L248.788 115.408Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M157.479 245.958L163.151 238.686L170.423 244.357L164.752 251.629L157.479 245.958Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M89.012 171.366L98.083 169.695L99.751 178.767L90.682 180.434L89.012 171.366Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M49.9453 3.5927L58.6112 0.438599L61.7653 9.1044L53.0994 12.2585L49.9453 3.5927Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M92.241 243.528L99.241 249.531L93.236 256.532L86.237 250.526L92.241 243.528Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M7.12037 87.632L13.5659 94.228L6.96967 100.674L0.52417 94.078L7.12037 87.632Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M93.846 104.086L27.3621 101.25L27.7996 90.995L94.284 93.83L93.846 104.086Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M114.448 59.4168L74.8716 24.0685L81.74 16.3796L121.315 51.7251L114.448 59.4168Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M168.099 228.711L157.789 228.855L157.058 175.797L167.368 175.653L168.099 228.711Z"
        fill={theme.colors.contentInversePrimary}
      />
      <path
        d="M95.168 126.531L57.7179 135.618L55.2863 125.595L92.736 116.508L95.168 126.531Z"
        fill={theme.colors.contentInversePrimary}
      />
    </System.Icon>
  )
}

Hammer.defaultProps = {
  width: 100,
}
