import * as React from "react"
import * as Illust from "../../assets/illust"
import { isClient, Log } from "../../utils"
import { loadFirebase } from "../firebase"

export const signIn = async (values, setFormLoading, setModal, setAlert) => {
  const url = isClient && window.location.href
  const actionCodeSettings = {
    url,
    handleCodeInApp: true,
  }
  try {
    setFormLoading(true)
    // firebase signIn request
    await loadFirebase().then(f =>
      f.auth().sendSignInLinkToEmail(values.email, actionCodeSettings)
    )
    Log.info(`signIn/sendSignInLinkToEmail success`)
    window.localStorage.setItem("emailForSignIn", values.email)
    setModal("")
    setAlert({
      show: true,
      kind: "positive",
      title: "Thanks",
      message: "Check your email box",
      body: <Illust.Done width={100} />,
    })
    setFormLoading(false)
  } catch (error) {
    console.error(`SiginUp/error`, error)
    setModal("")
    setAlert({
      show: true,
      kind: "negative",
      title: "error",
      message: error.message,
      body: <Illust.Hammer width={100} />,
    })
    setFormLoading(false)
  }
}
