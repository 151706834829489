import axios from "axios"
import * as React from "react"
import { useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import * as Illust from "../../assets/illust"
import { STRIPE_SERVERLESS_API_BASE } from "../../constants"
import { addUser, getUserDocumentByEmail, loadAuth } from "../../handlers"
import { UserDBT, UserT } from "../../types"
import { isClient, Log } from "../../utils"
import { alertState, loadingState, userState } from "./../../store"

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState)
  const setLoading = useSetRecoilState(loadingState)
  const setAlert = useSetRecoilState(alertState)
  Log.info(`User/user`, user)

  useEffect(() => {
    async function update() {
      setLoading("loading")
      let auth = await loadAuth()
      auth.onAuthStateChanged(async function (firebaseUser) {
        const isUserSignInNow =
          isClient && auth.isSignInWithEmailLink(window.location.href)

        // get user email
        let email
        if (firebaseUser) email = firebaseUser.email
        else if (isUserSignInNow) {
          email = window.localStorage.getItem("emailForSignIn")
          if (!email)
            email = window.prompt("Please provide your email for confirmation")
        }

        if (isUserSignInNow && !firebaseUser) {
          // get firebaseUser
          const signInResult = await auth.signInWithEmailLink(
            email,
            window.location.href
          )
        } else if (firebaseUser) {
          // add new user to DB (sign up) & set DB as store
          const isNewUser =
            firebaseUser.metadata.creationTime ===
            firebaseUser.metadata.lastSignInTime
          Log.info(`User/firebaseUser`, firebaseUser)

          if (isUserSignInNow && isNewUser) {
            // [Case 1] New User Sign Up
            const uid = firebaseUser.uid
            const userBasicInfo = await addUser({ email, uid })
            const userData = { uid, ...userBasicInfo }
            setUser(userData)
            window.localStorage.removeItem("emailForSignIn")

            setAlert({
              show: true,
              kind: "positive",
              title: "Welcome",
              message: `Signed up with ${email}`,
              body: <Illust.Done width={100} />,
            })
          } else if (isUserSignInNow && !isNewUser) {
            // [Case 2] Existing User Sign In
            await saveUserDBtoStore(email, user, setUser)

            if (isUserSignInNow) {
              setAlert({
                show: true,
                kind: "positive",
                title: "Done",
                message: `Signed with ${email}`,
                body: <Illust.Done width={100} />,
              })
            }
          }
          // [Case 3] Signed in User Page View
          else await saveUserDBtoStore(email, user, setUser)
        }
        setLoading("loaded")
      })
    }
    update()
  }, [])
}

async function saveUserDBtoStore(email, user, setUser) {
  // Only fetch user info once, if there is no user state
  if (!user.email) {
    let userDB: UserDBT = await getUserDocumentByEmail(email)
    // add subscription data and check is member from stripe
    const userData = await checkSubscription(userDB)

    setUser(userData)
  }
}

async function checkSubscription(userDB) {
  Log.info(`User/checkSubscription`)
  let userData: UserT = { ...userDB }

  // check subscription data from stripe
  if (userDB.subscription) {
    const { data: subscription } = await axios.post(
      STRIPE_SERVERLESS_API_BASE + "/subscriptions/retrieve",
      { id: userDB.subscription.id }
    )
    userData = {
      ...userData,
      subscription,
      member: subscription.status === "active",
    }
  }
  return userData
}
