import * as React from "react"

export function DoctorSitting({ $size }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={$size} viewBox="0 0 172 172">
      <path
        d="M 27.94 161.856 L 39.52 161.856 L 38.396 170.848 L 29.081 170.848 Z M 58.916 161.856 L 70.496 161.856 L 69.371 170.848 L 60.057 170.848 Z M 81.648 161.856 L 93.227 161.856 L 92.102 170.848 L 82.788 170.848 Z M 112.623 161.856 L 124.203 161.856 L 123.078 170.848 L 113.764 170.848 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 71.551 109.36 L 28.236 109.36 C 20.662 109.36 14.523 103.224 14.523 95.656 L 0.316 27.905 C 0.316 20.337 6.455 14.201 14.028 14.201 L 57.344 14.201 C 64.917 14.201 71.056 20.336 71.056 27.905 L 85.264 95.656 C 85.264 103.224 79.124 109.36 71.551 109.36 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 0.631 27.873 L 14.839 95.623 L 14.839 95.656 C 14.839 103.038 20.848 109.045 28.236 109.045 L 71.551 109.045 C 78.927 109.045 84.93 103.056 84.948 95.688 L 70.741 27.938 L 70.741 27.905 C 70.741 20.523 64.731 14.516 57.344 14.516 L 14.029 14.516 C 6.652 14.516 0.649 20.505 0.632 27.873 Z M 71.551 109.675 L 28.236 109.675 C 20.511 109.675 14.225 103.404 14.207 95.689 L 0 27.938 L 0 27.905 C 0 20.175 6.293 13.886 14.029 13.886 L 57.344 13.886 C 65.068 13.886 71.355 20.157 71.372 27.872 L 85.58 95.623 L 85.58 95.656 C 85.58 103.386 79.286 109.675 71.551 109.675 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 131.629 91.501 L 47.041 91.501 C 44.822 91.498 42.693 92.379 41.126 93.949 C 39.554 95.516 38.672 97.643 38.675 99.861 L 38.675 153.182 C 38.675 155.491 39.611 157.581 41.125 159.094 C 42.693 160.665 44.822 161.546 47.041 161.543 L 125.967 161.543 C 128.033 161.545 130.027 160.781 131.562 159.398 C 133.099 158.019 134.07 156.118 134.286 154.065 L 139.949 100.744 C 139.98 100.451 139.996 100.156 139.996 99.861 C 139.996 97.553 139.059 95.462 137.545 93.949 C 135.978 92.379 133.849 91.498 131.629 91.501 Z"
        fill="rgb(255,255,255)"
        strokeWidth="0.81"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M 145.959 115.527 C 139.836 100.983 131.264 82.093 115.41 76.866 C 105.756 74.878 95.762 75.786 86.037 77.236 L 83.871 63.572 C 95.4 61.836 107.472 60.891 118.962 63.495 C 140.046 69.821 151.046 91.62 158.848 110.479 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 83.448 63.318 C 95.763 61.522 108.754 60.272 120.923 63.751 C 141.04 71.053 151.647 92.107 159.261 110.667 L 145.773 115.918 C 140.157 102.265 132.769 86.456 119.565 78.847 C 110.467 74.309 95.731 76.204 85.747 77.6 Z M 84.293 63.827 L 86.329 76.873 C 96.24 75.435 105.944 74.562 115.63 76.599 C 131.443 81.917 139.993 100.588 146.144 115.135 L 158.436 110.294 C 150.778 91.874 140.118 70.794 119.805 64.067 C 108.281 61.101 95.868 62.142 84.293 63.827 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 150.289 128.275 C 147.392 118.248 143.425 108.41 138.336 99.319 L 150.392 92.507 C 154.828 100.374 158.4 108.713 161.329 117.217 C 161.384 117.391 161.592 117.488 161.759 117.413 C 165.488 116.091 170.282 117.546 171.501 122.444 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 150.479 92.019 C 155.013 100.001 158.651 108.474 161.628 117.115 C 165.72 115.62 170.813 117.467 171.93 122.802 L 150.039 128.556 C 147.117 118.374 143.086 108.393 137.897 99.183 Z M 150.304 92.994 L 138.776 99.458 C 143.756 108.407 147.65 118.089 150.522 127.934 L 171.081 122.166 C 169.81 117.647 165.125 116.542 161.864 117.721 C 161.535 117.854 161.137 117.656 161.031 117.32 C 158.148 108.953 154.644 100.746 150.304 92.994 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 149.979 127.228 L 149.8 126.623 L 170.772 120.422 L 170.952 121.027 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 161.142 116.673 C 161.869 118.765 162.55 120.873 163.182 122.996 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 163.182 123.311 C 163.043 123.311 162.92 123.219 162.88 123.085 C 162.249 120.967 161.57 118.863 160.844 116.776 C 160.787 116.611 160.874 116.431 161.039 116.374 C 161.204 116.317 161.383 116.405 161.44 116.569 C 162.17 118.666 162.852 120.778 163.485 122.906 C 163.513 123.001 163.495 123.105 163.435 123.184 C 163.376 123.264 163.282 123.311 163.182 123.311 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 164.595 117.023 C 165.163 118.706 165.7 120.399 166.207 122.102 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 166.206 122.417 C 166.066 122.417 165.943 122.325 165.904 122.191 C 165.398 120.492 164.862 118.802 164.296 117.123 C 164.24 116.958 164.329 116.779 164.494 116.723 C 164.66 116.668 164.839 116.757 164.894 116.922 C 165.463 118.609 166.002 120.306 166.509 122.012 C 166.538 122.107 166.519 122.211 166.46 122.29 C 166.4 122.37 166.306 122.417 166.206 122.417 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 163.292 109.097 L 143.086 116.964 C 137.572 103.67 128.838 84.265 114.173 79.859 C 97.239 77.093 79.876 81.11 63.398 85.747 C 54.247 88.86 43.836 79.925 49.381 70.818 C 53.517 63.998 63.491 63.59 70.608 61.437 C 85.746 58.044 101.428 55.853 116.992 58.323 C 141.519 63.164 154.704 87.948 163.292 109.097 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 145.707 116.259 C 145.579 116.259 145.463 116.182 145.415 116.064 C 138.903 100.27 131.048 84.779 117.778 78.425 C 114.102 76.867 109.959 76.677 105.952 76.494 L 105.618 76.478 C 97.48 76.342 89.015 77.185 79.75 79.051 C 72.143 80.584 63.914 82.84 54.593 85.95 C 54.427 86.005 54.249 85.916 54.193 85.751 C 54.138 85.585 54.227 85.406 54.393 85.351 C 63.739 82.234 71.992 79.971 79.625 78.433 C 88.935 76.557 97.443 75.711 105.637 75.848 L 105.981 75.863 C 110.044 76.05 114.244 76.242 118.038 77.85 C 131.512 84.302 139.439 99.913 145.999 115.823 C 146.039 115.921 146.028 116.032 145.969 116.119 C 145.911 116.207 145.812 116.259 145.707 116.259 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 150.696 114.317 C 150.567 114.317 150.451 114.239 150.403 114.119 C 147.489 106.916 142.859 96.326 136.312 87.553 C 129.177 77.994 121.222 72.647 112.668 71.661 C 95.744 69.704 74.496 73.213 49.513 82.09 C 49.406 82.13 49.286 82.109 49.199 82.035 C 49.112 81.962 49.071 81.847 49.093 81.735 C 49.114 81.624 49.194 81.532 49.302 81.495 C 74.376 72.586 95.72 69.066 112.74 71.035 C 121.474 72.041 129.575 77.471 136.818 87.176 C 143.409 96.007 148.062 106.648 150.988 113.883 C 151.027 113.98 151.016 114.091 150.957 114.178 C 150.899 114.265 150.801 114.317 150.696 114.317 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 155.684 112.375 C 155.556 112.374 155.44 112.297 155.392 112.178 C 148.104 94.38 138.28 75.22 120.672 68.061 C 116.487 66.481 111.816 66.094 107.766 65.855 C 90.173 65.211 71.058 68.78 47.62 77.085 C 47.459 77.135 47.286 77.048 47.23 76.889 C 47.173 76.729 47.252 76.554 47.409 76.49 C 70.924 68.158 90.112 64.578 107.796 65.225 C 111.899 65.467 116.627 65.86 120.902 67.473 C 138.735 74.723 148.639 94.019 155.976 111.939 C 156.016 112.037 156.005 112.147 155.946 112.235 C 155.887 112.322 155.789 112.374 155.684 112.375 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 160.673 110.433 C 160.545 110.433 160.429 110.355 160.38 110.236 C 152.097 90.019 140.761 68.486 119.48 62.065 C 114.305 60.749 108.832 60.479 104.163 60.39 C 87.767 60.415 70.345 63.716 49.342 70.781 C 49.177 70.836 48.998 70.747 48.942 70.582 C 48.886 70.417 48.975 70.238 49.14 70.183 C 70.21 63.096 87.696 59.783 104.169 59.759 C 108.88 59.849 114.399 60.121 119.649 61.457 C 129.559 64.447 138.014 70.801 145.511 80.886 C 152.476 90.255 157.428 101.365 160.965 109.997 C 161.005 110.095 160.994 110.206 160.935 110.293 C 160.877 110.38 160.778 110.433 160.673 110.433 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 109.041 137.902 C 111.524 125.219 114.891 112.195 114.122 99.282 C 111.568 92.01 91.243 90.937 84.183 90.845 L 84.407 77.013 C 98.608 77.467 120.94 78.796 127.092 94.201 C 130.437 105.951 125.002 128.505 122.563 140.866 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 84.057 76.691 C 98.536 77.127 120.972 78.425 127.341 93.959 C 130.82 106.648 125.321 128.109 122.803 141.246 L 108.667 138.136 C 111.18 125.501 114.446 112.534 113.857 99.669 C 112.131 92.336 90.391 91.175 83.856 91.155 Z M 84.756 77.335 L 84.512 90.536 C 91.92 90.695 110.618 91.636 114.309 98.726 C 114.685 100.274 114.627 101.648 114.638 103.365 C 114.231 114.866 111.791 126.359 109.416 137.668 L 122.324 140.488 C 125.057 126.928 128.3 113.214 127.727 99.262 C 127.541 95.9 126.482 92.606 124.416 89.922 C 115.75 78.9 97.752 77.774 84.756 77.335 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 105.793 151.665 C 108.428 141.182 110.779 130.533 112.539 119.885 L 126.208 122.11 C 124.795 130.724 122.989 139.273 121.005 147.75 C 120.985 147.836 121.002 147.927 121.051 148.001 C 121.101 148.074 121.179 148.124 121.266 148.138 C 124.799 148.887 128.449 152.441 127.106 157.116 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 126.576 121.815 C 125.153 130.553 123.325 139.225 121.312 147.822 C 125.228 148.639 128.912 152.574 127.327 157.544 L 105.417 151.859 C 108.108 141.185 110.5 130.349 112.281 119.507 Z M 125.84 122.405 L 112.797 120.263 C 111.056 130.716 108.752 141.162 106.173 151.452 L 126.869 156.709 C 128.022 152.353 124.232 149.036 121.192 148.448 C 120.848 148.388 120.61 148.018 120.697 147.677 C 122.654 139.32 124.436 130.894 125.84 122.405 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 127.301 155.772 L 106.081 150.481 L 106.234 149.869 L 127.454 155.16 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 121.135 147.199 C 120.641 149.312 120.131 151.422 119.607 153.529 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 119.607 153.844 C 119.51 153.844 119.418 153.799 119.358 153.723 C 119.299 153.646 119.277 153.547 119.301 153.452 C 119.806 151.427 120.305 149.358 120.828 147.127 C 120.867 146.957 121.037 146.852 121.207 146.891 C 121.376 146.931 121.482 147.1 121.442 147.27 C 120.948 149.385 120.439 151.497 119.913 153.605 C 119.878 153.745 119.752 153.844 119.607 153.844 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 123.881 149.295 C 123.486 150.962 123.081 152.628 122.667 154.291 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 122.667 154.607 C 122.57 154.607 122.478 154.563 122.418 154.486 C 122.358 154.41 122.337 154.31 122.36 154.215 C 122.756 152.63 123.153 150.997 123.574 149.222 C 123.622 149.062 123.787 148.967 123.95 149.006 C 124.112 149.044 124.217 149.203 124.188 149.367 C 123.793 151.037 123.388 152.704 122.973 154.368 C 122.938 154.508 122.812 154.607 122.667 154.607 Z M 41.043 77.306 C 41.115 53.949 82.966 53.951 83.034 77.307 C 82.962 100.663 41.111 100.66 41.043 77.306 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 41.043 59.789 L 62.037 59.789 L 62.037 94.81 L 50.356 94.81 C 45.212 94.81 41.043 90.643 41.043 85.503 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 127.03 142.175 L 105.851 137.517 C 108.3 125.118 111.545 112.454 110.969 99.84 C 110.969 99.848 110.971 99.856 110.974 99.864 C 110.969 99.871 110.973 99.866 110.973 99.867 C 110.978 99.866 110.967 99.869 110.974 99.867 L 110.976 99.867 C 110.968 99.869 110.962 99.873 110.973 99.868 C 110.971 99.869 110.97 99.87 110.968 99.871 C 110.964 99.873 110.964 99.873 110.971 99.869 C 110.98 99.864 110.968 99.872 110.965 99.873 L 110.969 99.871 C 104.535 94.354 90.68 94.259 82.187 94.022 C 75.483 94.009 68.613 94.411 61.762 95.098 C 58.679 95.408 55.759 94.547 53.468 92.883 C 48.663 89.642 47.274 82.114 51.388 77.7 C 56.737 71.057 73.801 73.038 82.248 72.35 C 97.657 72.747 115.661 73.424 127.12 85.389 C 131.17 89.787 132.842 95.627 132.802 101.495 C 132.784 115.294 129.825 128.793 127.03 142.175 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 93.562 91.497 L 23.928 91.497 C 21.475 91.494 19.146 92.569 17.559 94.437 C 16.117 96.123 15.408 98.315 15.588 100.525 L 19.859 153.846 C 20.024 155.942 20.976 157.898 22.523 159.323 C 24.068 160.75 26.095 161.542 28.199 161.54 L 88.648 161.54 C 90.809 161.54 92.793 160.717 94.285 159.356 C 95.828 157.953 96.789 156.023 96.979 153.946 L 101.893 100.625 C 101.916 100.37 101.928 100.115 101.928 99.858 C 101.928 97.55 100.992 95.459 99.478 93.946 C 97.91 92.376 95.781 91.495 93.562 91.497 Z"
        fill="rgb(255,255,255)"
        strokeWidth="0.81"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M 127.26 9.998 C 109.756 11.394 112.726 6.494 112.583 24.522 L 127.487 24.748 C 127.56 19.778 127.462 14.871 127.26 9.998 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 112.901 24.211 L 127.176 24.429 C 127.235 20.02 127.164 15.403 126.958 10.339 C 123.374 10.612 120.639 10.623 118.627 10.632 C 115.773 10.643 114.202 10.65 113.494 11.376 C 112.708 12.182 112.75 14.085 112.833 17.876 C 112.871 19.569 112.916 21.658 112.901 24.211 Z M 127.488 25.064 L 127.483 25.064 L 112.579 24.838 C 112.405 24.835 112.266 24.693 112.268 24.52 C 112.289 21.832 112.24 19.647 112.202 17.89 C 112.112 13.799 112.071 11.931 113.041 10.936 C 113.934 10.019 115.501 10.013 118.624 10.001 C 120.687 9.992 123.513 9.981 127.235 9.684 C 127.32 9.677 127.405 9.705 127.469 9.762 C 127.533 9.819 127.572 9.899 127.575 9.985 C 127.796 15.312 127.871 20.143 127.803 24.753 C 127.801 24.926 127.66 25.064 127.488 25.064 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 116.127 11.358 C 116.035 9.044 115.923 6.731 115.789 4.419 C 115.602 1.515 111.91 1.745 112.069 4.636 C 112.232 7.444 112.364 10.249 112.458 13.049"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 112.458 13.365 C 112.288 13.365 112.148 13.23 112.142 13.06 C 112.047 10.256 111.918 7.454 111.754 4.654 C 111.664 3.022 112.706 2.104 113.781 2.04 C 115.203 1.955 116.024 3.162 116.104 4.399 C 116.246 6.871 116.356 9.143 116.443 11.346 C 116.449 11.52 116.314 11.666 116.14 11.673 C 115.965 11.68 115.819 11.544 115.812 11.37 C 115.72 9.058 115.607 6.747 115.474 4.437 C 115.392 3.16 114.556 2.625 113.819 2.67 C 113.079 2.714 112.315 3.343 112.384 4.618 C 112.553 7.526 112.684 10.359 112.773 13.039 C 112.776 13.122 112.746 13.204 112.689 13.265 C 112.631 13.326 112.552 13.362 112.468 13.365 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 116.127 11.358 C 116.035 9.044 115.923 6.731 115.789 4.419 C 115.715 3.192 116.485 2.143 117.512 2.077 C 118.539 2.01 119.434 2.962 119.51 4.203 C 119.643 6.538 119.759 8.875 119.851 11.215"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 116.127 11.673 C 115.958 11.673 115.818 11.539 115.812 11.37 C 115.72 9.058 115.607 6.747 115.474 4.437 C 115.388 3.04 116.294 1.839 117.492 1.762 C 118.01 1.728 118.522 1.912 118.938 2.279 C 119.455 2.735 119.779 3.429 119.824 4.183 C 119.967 6.681 120.079 8.976 120.166 11.202 C 120.173 11.376 120.037 11.523 119.863 11.53 C 119.689 11.536 119.542 11.401 119.536 11.227 C 119.443 8.89 119.329 6.555 119.194 4.22 C 119.158 3.634 118.912 3.098 118.52 2.752 C 118.227 2.493 117.885 2.369 117.532 2.392 C 116.681 2.447 116.04 3.348 116.104 4.399 C 116.245 6.871 116.356 9.142 116.443 11.345 C 116.449 11.519 116.314 11.666 116.14 11.673 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 119.851 11.215 C 119.758 8.877 119.644 6.539 119.509 4.203 C 119.434 2.964 120.204 1.906 121.231 1.84 C 122.258 1.773 123.153 2.734 123.23 3.986 C 123.366 6.347 123.481 8.709 123.575 11.071"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 119.851 11.53 C 119.681 11.53 119.542 11.396 119.536 11.227 C 119.443 8.89 119.329 6.555 119.195 4.22 C 119.108 2.812 120.013 1.603 121.211 1.525 C 121.722 1.492 122.236 1.675 122.65 2.04 C 123.172 2.5 123.498 3.202 123.545 3.967 C 123.689 6.49 123.802 8.81 123.89 11.059 C 123.897 11.234 123.761 11.38 123.587 11.387 C 123.413 11.394 123.266 11.258 123.26 11.084 C 123.166 8.723 123.051 6.363 122.915 4.004 C 122.878 3.407 122.63 2.863 122.233 2.513 C 121.94 2.256 121.601 2.132 121.252 2.155 C 120.4 2.21 119.76 3.12 119.825 4.184 C 119.967 6.681 120.079 8.976 120.167 11.202 C 120.173 11.376 120.038 11.523 119.864 11.53 L 119.851 11.53 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 114.205 24.238 C 109.503 24.48 108.244 22.528 108.566 18.088 C 108.285 15.002 108.604 11.835 112.442 11.499 C 113.006 12.592 113.575 13.691 114.128 14.795"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 112.583 24.522 C 110.935 24.497 108.82 24.441 108.82 24.441 C 108.884 22.702 108.888 19.89 108.855 18.085 C 108.592 15.176 108.838 11.789 112.404 11.501 C 112.442 12.614 112.485 13.728 112.512 14.838"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 112.583 24.838 L 112.578 24.838 C 110.947 24.813 108.833 24.757 108.812 24.756 C 108.728 24.754 108.648 24.718 108.59 24.657 C 108.533 24.595 108.502 24.513 108.505 24.429 C 108.57 22.663 108.571 19.854 108.539 18.102 C 108.387 16.408 108.332 13.989 109.589 12.498 C 110.241 11.725 111.179 11.283 112.378 11.187 C 112.464 11.18 112.549 11.208 112.613 11.265 C 112.678 11.323 112.716 11.404 112.719 11.49 L 112.746 12.258 C 112.776 13.102 112.807 13.974 112.827 14.831 C 112.831 15.005 112.693 15.15 112.519 15.154 C 112.345 15.158 112.2 15.021 112.196 14.846 C 112.174 13.991 112.147 13.136 112.115 12.28 L 112.1 11.852 C 111.225 11.98 110.56 12.326 110.072 12.905 C 109.229 13.905 108.942 15.542 109.169 18.056 C 109.17 18.064 109.17 18.071 109.17 18.078 C 109.2 19.737 109.201 22.348 109.147 24.134 C 109.8 24.15 111.336 24.188 112.588 24.207 C 112.762 24.208 112.902 24.351 112.901 24.525 C 112.9 24.699 112.757 24.839 112.583 24.838 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 112.524 15.283 C 112.491 14.031 112.451 12.78 112.404 11.529 C 112.276 8.658 108.58 8.82 108.681 11.672 C 108.788 14.427 108.853 17.183 108.876 19.94"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 108.876 20.256 C 108.702 20.256 108.561 20.116 108.56 19.943 C 108.537 17.189 108.472 14.436 108.365 11.684 C 108.308 10.07 109.369 9.183 110.446 9.14 C 111.861 9.083 112.665 10.292 112.72 11.515 C 112.768 12.852 112.81 14.055 112.839 15.275 C 112.843 15.449 112.706 15.594 112.531 15.598 C 112.357 15.602 112.212 15.465 112.208 15.291 C 112.176 14.04 112.136 12.79 112.089 11.54 C 112.032 10.283 111.217 9.741 110.471 9.77 C 109.73 9.8 108.952 10.404 108.996 11.66 C 109.107 14.543 109.172 17.328 109.192 19.939 C 109.193 20.113 109.052 20.255 108.878 20.256 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 123.575 11.071 C 123.83 8.922 122.076 3.616 125.049 3.207 C 128.103 3.294 126.88 8.795 127.299 10.928"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 123.575 11.387 C 123.485 11.387 123.399 11.349 123.339 11.281 C 123.279 11.214 123.251 11.124 123.261 11.035 C 123.33 10.457 123.24 9.596 123.145 8.684 C 122.893 6.253 122.578 3.229 125.006 2.894 C 125.023 2.892 125.04 2.891 125.058 2.891 C 127.58 2.964 127.546 6.08 127.518 8.585 C 127.508 9.473 127.499 10.312 127.608 10.868 C 127.638 11.037 127.527 11.198 127.358 11.231 C 127.189 11.265 127.025 11.157 126.988 10.989 C 126.867 10.369 126.877 9.499 126.887 8.578 C 126.918 5.758 126.803 3.595 125.066 3.523 C 123.404 3.774 123.489 5.886 123.774 8.618 C 123.872 9.566 123.965 10.462 123.888 11.109 C 123.87 11.267 123.735 11.387 123.575 11.387 Z M 129.16 24.441 L 106.911 24.139 C 106.736 31.395 106.519 38.539 104.489 45.348 C 101.243 42.786 98.558 39.643 95.925 36.338 C 94.577 34.619 93.277 32.862 92.027 31.07 C 88.688 26.386 82.111 25.59 77.116 28.484 C 71.705 31.605 70.172 38.661 73.789 43.808 C 76.835 48.146 80.004 52.274 83.667 56.239 C 88.609 61.536 94.672 66.825 101.92 68.484 C 109.759 70.486 118.125 66.584 122.204 59.706 C 128.492 49.24 128.789 36.168 129.16 24.441 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 89.567 75.677 C 89.644 76.5 89.37 77.318 88.813 77.929 C 88.256 78.54 87.467 78.889 86.64 78.889 L 39.955 78.889 C 38.43 78.889 37.14 77.76 36.938 76.249 L 39.355 34.011 C 38.68 28.95 42.62 24.453 47.728 24.453 L 78.262 24.453 C 84.881 24.502 90.098 30.422 89.552 37.004 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 75.824 24.453 C 72.001 8.367 43.581 19.739 62.037 33.167 C 62.369 33.435 62.85 33.41 63.151 33.108 L 65.34 30.92 L 67.522 28.74 C 68.566 29.755 70.715 31.839 71.766 32.861 C 73.376 34.519 75.953 26.501 75.824 24.453 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 66.725 34.26 L 64.484 32.02 L 67.602 28.903 L 70.721 32.02 L 68.473 34.266 L 70.662 40.902 L 75.824 24.453 L 55.918 24.453 L 65.857 41.016 C 65.86 41.004 66.72 34.269 66.725 34.26 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 71.096 61.013 L 68.473 34.266 L 70.72 32.02 L 67.602 28.903 L 64.484 32.02 L 66.725 34.26 C 66.722 34.257 63.135 61.124 63.133 61.127 C 67.804 66.814 66.85 67.775 71.096 61.013 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 62.72 33.499 C 62.441 33.499 62.11 33.398 61.727 33.196 C 59.339 31.939 55.302 26.936 55.684 24.418 C 55.71 24.295 55.827 24.214 55.951 24.233 C 56.075 24.252 56.163 24.364 56.152 24.489 C 56 25.495 56.716 27.193 58.068 29.029 C 59.296 30.696 60.855 32.202 61.948 32.777 C 62.236 32.93 62.756 33.153 62.99 32.935 C 63.583 32.341 64.517 31.408 65.417 30.508 L 67.354 28.573 C 67.446 28.481 67.594 28.48 67.687 28.57 L 71.931 32.691 C 72.089 32.853 72.232 32.887 72.414 32.806 C 73.783 32.201 75.703 26.286 75.588 24.468 C 75.579 24.338 75.679 24.225 75.809 24.217 C 75.94 24.209 76.052 24.308 76.06 24.438 C 76.177 26.283 74.251 32.511 72.605 33.239 C 72.243 33.399 71.885 33.323 71.596 33.026 L 67.524 29.073 L 65.752 30.843 C 64.941 31.653 64.13 32.464 63.319 33.275 C 63.159 33.425 62.959 33.499 62.72 33.499 Z M 73.071 39.803 C 73.126 41.277 73.085 42.595 73.049 43.765 C 72.97 46.337 72.912 48.195 73.988 49.231 C 75.03 50.234 77.138 50.548 81.041 50.282 L 81.054 50.282 C 82.074 50.264 82.84 49.967 83.398 49.372 C 84.738 47.941 84.598 45.074 84.474 42.545 C 84.425 41.535 84.378 40.576 84.437 39.803 Z M 78.71 50.844 C 76.121 50.844 74.561 50.44 73.66 49.572 C 72.432 48.39 72.492 46.444 72.576 43.75 C 72.614 42.523 72.656 41.133 72.588 39.577 L 72.577 39.33 L 84.963 39.33 L 84.932 39.594 C 84.842 40.368 84.893 41.414 84.948 42.522 C 85.076 45.145 85.221 48.118 83.743 49.695 C 83.104 50.378 82.204 50.734 81.068 50.755 C 80.283 50.811 79.497 50.841 78.71 50.844 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 63.696 91.585 C 67.555 72.874 72.119 77.314 54.659 71.852 C 49.578 89.978 45.525 86.44 59.884 90.587 C 58.612 90.242 57.342 89.886 56.074 89.521 C 54.712 93.591 62.814 95.797 63.696 91.585 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 56.292 89.919 C 56.075 90.926 56.533 91.677 56.98 92.145 C 58.062 93.275 60.087 93.825 61.59 93.395 C 62.272 93.201 63.136 92.718 63.387 91.521 C 64.302 87.087 65.252 83.958 65.948 81.674 C 66.778 78.941 67.236 77.435 66.745 76.536 C 66.194 75.527 64.313 74.993 60.564 73.927 C 59.028 73.49 57.132 72.952 54.874 72.25 C 53.617 76.684 52.44 79.771 51.575 82.04 C 50.569 84.679 50.015 86.133 50.432 86.949 C 50.877 87.818 52.527 88.26 55.813 89.138 L 56.069 89.206 C 56.104 89.206 56.131 89.21 56.162 89.218 C 56.379 89.281 56.598 89.344 56.821 89.408 C 57.76 89.661 58.799 89.945 59.972 90.284 C 60.139 90.332 60.236 90.505 60.188 90.672 C 60.142 90.839 59.969 90.937 59.802 90.891 C 58.75 90.606 57.7 90.314 56.652 90.016 Z M 60.478 94.174 C 59.026 94.174 57.481 93.581 56.524 92.581 C 55.746 91.767 55.453 90.775 55.682 89.756 L 55.65 89.747 C 52.075 88.792 50.443 88.356 49.87 87.236 C 49.324 86.17 49.88 84.715 50.985 81.815 C 51.866 79.505 53.072 76.341 54.354 71.767 C 54.378 71.685 54.433 71.616 54.508 71.575 C 54.583 71.535 54.671 71.526 54.753 71.551 C 57.14 72.298 59.135 72.865 60.737 73.32 C 64.778 74.469 66.622 74.993 67.299 76.234 C 67.917 77.366 67.46 78.867 66.552 81.857 C 65.86 84.13 64.914 87.243 64.005 91.649 L 64.005 91.65 C 63.76 92.824 62.963 93.66 61.764 94.002 C 61.357 94.118 60.922 94.174 60.478 94.174 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 48.84 90.642 L 55.74 69.502 C 48.927 67.127 39.164 63.937 34.387 58.534 C 34.387 58.534 34.392 58.532 34.385 58.535 L 34.387 58.535 C 34.387 58.535 34.389 58.536 34.386 58.536 C 34.392 58.537 34.385 58.536 34.387 58.536 C 34.382 58.534 34.392 58.538 34.388 58.537 C 34.383 58.533 34.397 58.522 34.4 58.513 C 37.079 54.389 41.691 51.711 46.022 49.11 C 47.939 48.02 49.945 46.981 51.999 45.995 C 57.232 43.443 59.429 37.124 57.461 31.599 C 55.283 25.526 48.199 23.115 42.371 25.949 C 37.284 28.403 32.427 31.074 27.72 34.405 C 13.421 43.949 5.389 60.554 18.998 74.709 C 27.16 82.882 38.173 87.023 48.84 90.642 Z M 64.859 16.197 C 64.859 18.501 62.99 20.368 60.686 20.368 C 59.185 20.383 57.791 19.592 57.036 18.295 C 56.281 16.998 56.281 15.396 57.036 14.1 C 57.791 12.803 59.185 12.011 60.686 12.027 C 62.991 12.027 64.859 13.894 64.859 16.197 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 77.1 8.697 C 77.1 13.501 72.82 17.394 67.54 17.394 C 62.259 17.394 57.978 13.501 57.978 8.697 C 57.978 3.894 62.259 0 67.54 0 C 72.82 0 77.1 3.894 77.1 8.697 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 66.997 25.898 C 64.004 25.898 61.246 23.605 61.246 20.866 L 63.789 11.455 C 63.789 8.717 63.057 6.497 66.05 6.497 L 69.207 6.497 C 72.2 6.497 71.468 8.717 71.468 11.454 L 71.468 20.94 C 71.468 23.678 69.989 25.898 66.997 25.898 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 61.562 20.908 C 61.589 23.403 64.117 25.583 66.997 25.583 C 70.061 25.583 71.152 23.184 71.152 20.941 L 71.152 11.455 C 71.152 10.974 71.174 10.517 71.196 10.075 C 71.253 8.859 71.304 7.81 70.802 7.283 C 70.499 6.967 69.978 6.812 69.207 6.812 L 66.05 6.812 C 65.28 6.812 64.758 6.967 64.456 7.283 C 63.953 7.81 64.004 8.86 64.062 10.075 C 64.083 10.517 64.104 10.974 64.104 11.455 L 64.104 11.497 L 61.562 20.908 Z M 66.997 26.214 C 63.765 26.214 60.93 23.715 60.93 20.866 L 60.93 20.825 L 63.473 11.413 C 63.472 10.962 63.451 10.526 63.431 10.105 C 63.366 8.745 63.31 7.57 63.999 6.848 C 64.427 6.399 65.097 6.181 66.05 6.181 L 69.207 6.181 C 70.16 6.181 70.831 6.399 71.259 6.848 C 71.947 7.57 71.891 8.745 71.826 10.105 C 71.801 10.555 71.787 11.005 71.784 11.455 L 71.784 20.941 C 71.784 24.144 69.905 26.214 66.997 26.214 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 77.468 10.202 C 77.468 11.529 76.392 12.605 75.064 12.605 C 73.736 12.605 72.66 11.529 72.66 10.202 C 72.66 8.875 73.736 7.799 75.064 7.799 C 76.392 7.799 77.468 8.875 77.468 10.202 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 75.064 8.115 C 73.911 8.116 72.976 9.05 72.975 10.202 C 72.975 11.354 73.912 12.29 75.064 12.29 C 76.216 12.29 77.153 11.354 77.153 10.202 C 77.153 9.051 76.216 8.115 75.064 8.115 Z M 75.064 12.921 C 73.562 12.919 72.346 11.703 72.344 10.203 C 72.345 8.702 73.562 7.486 75.064 7.484 C 76.566 7.486 77.782 8.702 77.784 10.202 C 77.783 11.703 76.566 12.919 75.064 12.921 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 68.828 17.684 C 66.073 17.685 63.581 16.049 62.489 13.521 L 62.387 13.283 C 60.877 9.792 62.492 5.724 65.985 4.216 C 66.848 3.841 67.779 3.648 68.719 3.649 C 71.476 3.649 73.965 5.283 75.058 7.812 L 75.161 8.049 C 75.892 9.728 75.921 11.628 75.241 13.328 C 74.57 15.031 73.245 16.395 71.562 17.116 C 70.7 17.491 69.769 17.684 68.828 17.684 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 68.719 3.333 C 67.733 3.333 66.772 3.533 65.86 3.927 C 64.099 4.681 62.714 6.108 62.013 7.889 C 61.302 9.666 61.332 11.654 62.097 13.409 L 62.2 13.646 C 63.341 16.29 65.947 18.001 68.828 18 C 69.814 18 70.776 17.8 71.688 17.406 C 73.448 16.652 74.833 15.226 75.534 13.445 C 76.245 11.667 76.215 9.68 75.45 7.925 L 75.348 7.687 C 74.206 5.043 71.6 3.332 68.719 3.333 Z M 68.719 3.964 C 71.268 3.964 73.693 5.451 74.768 7.938 L 74.871 8.175 C 76.313 11.512 74.776 15.386 71.437 16.827 C 70.613 17.184 69.726 17.368 68.828 17.369 C 66.28 17.369 63.854 15.882 62.779 13.396 L 62.676 13.158 C 61.234 9.821 62.771 5.947 66.11 4.506 C 66.934 4.149 67.822 3.965 68.719 3.964 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 62.364 12.605 C 61.445 12.606 60.607 12.084 60.202 11.261 C 59.798 10.437 59.898 9.455 60.46 8.73 C 61.022 8.005 61.949 7.663 62.848 7.848"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 62.364 12.921 C 60.862 12.919 59.645 11.703 59.643 10.203 C 59.644 9.386 60.012 8.613 60.645 8.097 C 61.279 7.58 62.111 7.376 62.912 7.539 C 63.082 7.574 63.193 7.741 63.158 7.912 C 63.123 8.082 62.956 8.192 62.785 8.157 C 62.17 8.032 61.531 8.189 61.044 8.585 C 60.558 8.981 60.275 9.575 60.275 10.203 C 60.275 11.354 61.212 12.29 62.364 12.29 C 62.538 12.29 62.679 12.431 62.679 12.606 C 62.679 12.78 62.538 12.921 62.364 12.921 Z M 77.448 6.497 L 69.334 6.497 C 66.377 6.497 63.98 4.101 63.98 1.146 C 63.98 0.513 66.344 0 66.978 0 L 72.094 0 C 75.051 0 77.448 2.395 77.448 5.35 L 77.448 6.496 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 63.932 8.519 C 65.152 8.519 66.142 7.53 66.142 6.311 C 66.142 5.091 65.153 4.102 63.932 4.102 C 62.712 4.102 61.722 5.09 61.722 6.31 C 61.722 7.53 62.711 8.518 63.932 8.518 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 69.796 12.934 L 70.989 12.934 C 71.319 12.934 71.562 12.456 71.489 11.955 L 70.986 8.525 C 70.87 7.729 70.155 7.685 69.998 8.463 L 69.308 11.893 C 69.204 12.409 69.45 12.934 69.796 12.934 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 75.254 7.414 C 75.737 7.414 76.132 7.797 76.15 8.276 L 76.15 8.311 L 76.15 12.274 L 76.164 12.399 C 76.335 13.954 77.818 29.511 69.225 29.511 C 60.524 29.511 62.061 14.535 62.277 12.738 L 62.299 12.563 L 62.299 8.311 C 62.299 7.816 62.701 7.414 63.196 7.414 C 63.691 7.414 64.093 7.816 64.093 8.311 L 64.093 11.867 C 64.093 12.681 64.653 13.826 65.532 14.845 L 65.515 14.707 C 65.41 13.787 65.446 12.817 65.884 12.207 C 66.184 11.789 66.639 11.568 67.199 11.568 C 68.198 11.568 68.786 11.809 69.248 12.031 L 69.538 12.172 C 70.017 12.399 70.365 12.487 71.322 12.048 L 71.38 12.022 C 72.478 11.507 73.144 11.429 73.608 11.762 C 74.208 12.192 74.146 13.064 74.013 14.269 C 74.347 13.509 74.383 12.841 74.373 12.504 C 74.365 12.463 74.36 12.42 74.358 12.378 L 74.357 12.334 L 74.357 8.311 C 74.357 7.816 74.758 7.414 75.254 7.414 Z M 71.835 12.854 L 71.782 12.878 C 70.446 13.504 69.82 13.352 69.088 13.005 L 68.896 12.912 C 68.481 12.71 68.032 12.515 67.199 12.515 C 66.871 12.515 66.735 12.645 66.653 12.759 C 66.236 13.341 66.421 14.804 66.714 15.972 C 67.161 16.318 67.645 16.614 68.149 16.828 L 69.2 15.778 C 69.355 15.624 69.564 15.537 69.782 15.537 C 70 15.537 70.21 15.624 70.364 15.778 L 71.412 16.824 C 72.033 16.514 72.525 16.164 72.915 15.799 C 72.94 15.39 72.98 15 73.019 14.637 L 73.087 14.022 C 73.15 13.417 73.206 12.679 73.068 12.541 L 73.054 12.53 C 73.03 12.517 72.788 12.415 71.835 12.854 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 70.34 16.035 C 69.897 16.083 69.462 15.889 69.202 15.527 C 68.942 15.166 68.896 14.692 69.083 14.288 C 69.269 13.883 69.659 13.61 70.103 13.572 C 70.779 13.515 71.375 14.012 71.44 14.686 C 71.505 15.361 71.015 15.963 70.34 16.035 Z M 69.136 8.936 C 68.961 8.936 68.82 8.795 68.82 8.621 C 68.82 8.395 68.55 8.045 68.191 8.045 C 67.832 8.045 67.562 8.395 67.562 8.621 C 67.562 8.795 67.421 8.936 67.247 8.936 C 67.072 8.936 66.931 8.795 66.931 8.621 C 66.931 8.063 67.481 7.414 68.191 7.414 C 68.901 7.414 69.451 8.063 69.451 8.621 C 69.451 8.795 69.31 8.936 69.136 8.936 Z M 73.225 8.936 C 73.142 8.937 73.061 8.903 73.002 8.844 C 72.943 8.785 72.909 8.705 72.909 8.621 C 72.909 8.395 72.64 8.045 72.281 8.045 C 71.922 8.045 71.652 8.395 71.652 8.621 C 71.646 8.791 71.507 8.925 71.337 8.925 C 71.167 8.925 71.027 8.791 71.021 8.621 C 71.021 8.063 71.571 7.414 72.281 7.414 C 72.99 7.414 73.541 8.063 73.541 8.621 C 73.541 8.795 73.4 8.936 73.225 8.936 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 73.435 5.679 C 74.913 5.681 76.111 6.878 76.112 8.355 C 76.11 9.832 74.913 11.029 73.435 11.031 C 72.679 11.03 71.958 10.71 71.451 10.15 C 70.943 9.59 70.696 8.842 70.77 8.09 C 70.58 7.954 70.4 8.005 70.281 8.074 C 70.36 8.828 70.115 9.58 69.608 10.144 C 69.1 10.708 68.377 11.03 67.619 11.031 C 66.143 11.029 64.946 9.836 64.941 8.362 L 63.143 8.362 C 62.968 8.362 62.827 8.22 62.827 8.046 C 62.827 7.872 62.968 7.731 63.143 7.731 L 65.015 7.731 C 65.305 6.529 66.381 5.681 67.619 5.679 C 68.747 5.68 69.754 6.387 70.138 7.448 C 70.369 7.361 70.643 7.337 70.915 7.45 C 71.298 6.389 72.305 5.68 73.435 5.679 Z M 67.619 6.31 C 66.489 6.311 65.574 7.226 65.572 8.355 C 65.574 9.484 66.489 10.398 67.619 10.4 C 68.748 10.398 69.663 9.484 69.665 8.355 C 69.664 7.226 68.748 6.311 67.619 6.31 Z M 73.435 6.31 C 72.305 6.311 71.39 7.226 71.388 8.355 C 71.39 9.484 72.305 10.398 73.435 10.4 C 74.564 10.398 75.479 9.484 75.481 8.355 C 75.48 7.226 74.564 6.311 73.435 6.31 Z"
        fill="rgb(41,69,255)"
      ></path>
    </svg>
  )
}

DoctorSitting.defaultProps = {
  $size: 172,
}
