import * as React from "react"

export function DoctorPrescription({ $size }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={$size} viewBox="0 0 182 171">
      <path
        d="M 140.516 10.08 L 140.516 85.202 L 180.786 162.415 C 181.67 164.112 181.605 166.147 180.613 167.783 C 179.621 169.419 177.846 170.419 175.933 170.419 L 77.579 170.419 C 75.665 170.419 73.891 169.42 72.899 167.784 C 71.907 166.147 71.841 164.112 72.726 162.415 L 112.824 85.529 L 112.824 10.08 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 115.294 124.39 C 125.866 123.291 127.698 118.798 135.916 118.798 C 141.395 118.798 146.639 120.662 151.649 124.39 L 169.415 158.388 C 169.838 159.198 169.807 160.169 169.334 160.951 C 168.86 161.732 168.013 162.209 167.1 162.21 L 86.661 162.21 C 85.747 162.21 84.9 161.733 84.427 160.951 C 83.953 160.17 83.923 159.198 84.346 158.388 L 103.259 122.195 C 104.234 124.391 108.246 125.123 115.294 124.39 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 148.027 1 L 105.236 1 L 105.236 10.08 L 148.027 10.08 Z M 112.7 127.007 L 84.96 127.007 L 84.96 134.221 L 112.7 134.221 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 110.523 131.423 L 87.013 131.423 C 84.128 131.423 81.789 133.762 81.789 136.647 L 81.789 165.008 C 81.789 166.394 82.339 167.723 83.319 168.703 C 84.298 169.682 85.627 170.233 87.013 170.233 L 110.523 170.233 C 113.409 170.233 115.748 167.894 115.748 165.008 L 115.748 136.647 C 115.748 135.261 115.197 133.932 114.218 132.953 C 113.238 131.973 111.909 131.423 110.523 131.423 Z M 115.934 118.3 L 81.603 118.3 L 81.603 129.122 L 115.934 129.122 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 115.685 141.685 L 81.975 141.685 L 81.975 162.458 L 115.685 162.458 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 127.254 159.598 L 117.428 159.598 C 114.44 159.598 112.017 162.021 112.017 165.009 C 112.017 167.997 114.44 170.42 117.428 170.42 L 127.254 170.42 C 130.242 170.42 132.665 167.997 132.665 165.009 C 132.665 162.021 130.242 159.598 127.254 159.598 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 122.341 159.598 L 122.341 170.42 L 117.428 170.42 C 114.44 170.42 112.017 167.997 112.017 165.009 C 112.017 162.021 114.44 159.598 117.428 159.598 Z"
        fill="rgb(0,0,0)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 112.577 159.598 L 102.75 159.598 C 99.762 159.598 97.339 162.021 97.339 165.009 C 97.339 167.997 99.762 170.42 102.75 170.42 L 112.577 170.42 C 115.525 170.363 117.885 167.957 117.885 165.009 C 117.885 162.061 115.525 159.655 112.577 159.598 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 107.663 159.598 L 107.663 170.42 L 102.75 170.42 C 99.762 170.42 97.339 167.997 97.339 165.009 C 97.339 162.021 99.762 159.598 102.75 159.598 Z"
        fill="rgb(0,0,0)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 59.71 159.598 L 69.537 159.598 C 72.525 159.598 74.948 162.021 74.948 165.009 C 74.948 167.997 72.525 170.42 69.537 170.42 L 59.71 170.42 C 56.722 170.42 54.299 167.997 54.299 165.009 C 54.299 162.021 56.722 159.598 59.71 159.598 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 64.624 159.598 L 64.624 170.42 L 69.537 170.42 C 72.525 170.42 74.948 167.997 74.948 165.009 C 74.948 162.021 72.525 159.598 69.537 159.598 Z"
        fill="rgb(0,0,0)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 85.21 133.849 L 73.518 133.849 L 73.518 139.944 L 85.21 139.944 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 83.033 137.519 L 75.694 137.519 C 72.809 137.519 70.47 139.858 70.47 142.743 L 70.47 165.009 C 70.47 167.894 72.809 170.233 75.694 170.233 L 83.034 170.233 C 85.919 170.232 88.257 167.894 88.257 165.009 L 88.257 142.743 C 88.257 139.858 85.918 137.519 83.033 137.519 Z M 88.444 130.49 L 70.284 130.49 L 70.284 135.466 L 88.444 135.466 Z"
        fill="rgb(255,255,255)"
        strokeWidth="1.023"
        stroke="rgb(0,0,0)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 88.195 146.163 L 70.53 146.163 L 70.53 163.827 L 88.194 163.827 L 88.194 146.163 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 23.864 158.375 C 25.296 137.667 28.237 116.522 30.392 95.89 L 41.244 97 C 39.104 117.526 36.17 138.579 34.746 159.144 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 41.516 96.785 C 39.367 117.47 36.399 138.684 34.976 159.406 L 23.599 158.609 C 25.029 137.726 28.002 116.432 30.169 95.627 Z M 40.972 97.215 L 30.615 96.151 C 28.47 116.61 25.561 137.615 24.13 158.14 L 34.514 158.881 C 35.936 138.508 38.844 117.551 40.972 97.215 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 30.252 170.22 C 30.431 167.101 25.729 166.945 25.816 170.166 L 23.268 170.135 C 23.491 161.399 24.274 152.699 25.075 144.017 L 35.939 145.048 C 35.788 146.619 35.644 148.19 35.506 149.762 C 35.331 154.684 34.134 160.232 34.664 164.831 C 37.151 164.875 40.737 166.861 40.602 170.346 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 36.21 144.835 C 35.586 151.382 35.023 158.06 34.655 164.587 C 37.321 164.637 41.027 166.752 40.852 170.461 L 29.965 170.461 C 30.48 167.316 25.616 167.235 26.097 170.461 L 23.016 170.461 C 23.214 161.548 24.036 152.618 24.85 143.753 Z M 35.669 145.263 L 25.299 144.282 C 24.515 152.783 23.759 161.31 23.52 169.86 L 25.568 169.895 C 25.728 166.718 30.438 166.857 30.508 169.98 L 40.345 170.148 C 40.382 166.913 36.921 165.107 34.651 165.075 C 34.517 165.074 34.389 165.018 34.296 164.921 C 34.203 164.824 34.153 164.694 34.158 164.56 C 34.434 159.641 34.821 154.69 35.258 149.741 C 35.354 148.631 35.559 146.373 35.668 145.263 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 40.118 168.372 L 23.323 167.719 L 23.343 167.222 L 40.138 167.875 Z M 34.88 160.849 C 33.992 160.786 33.306 160.346 32.948 159.608 C 32.565 158.8 32.633 157.851 33.128 157.107 C 33.589 156.427 34.331 156.087 35.218 156.151 L 35.182 156.647 C 34.48 156.597 33.897 156.859 33.54 157.386 C 33.149 157.984 33.094 158.742 33.395 159.39 C 33.673 159.961 34.212 160.303 34.915 160.353 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 40.118 168.372 L 23.323 167.719 L 23.343 167.222 L 40.138 167.875 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 46.836 102.58 L 24.899 100.213 C 25.694 96.734 26.466 93.25 27.213 89.76 C 27.958 86.307 28.66 82.845 29.32 79.374 C 29.592 74.758 33.818 71.974 38.12 71.747 C 40.396 71.497 42.663 72.613 44.17 74.269 C 45.721 75.961 46.516 78.213 46.37 80.504 C 46.538 84.239 46.63 87.914 46.704 91.591 C 46.769 95.032 46.82 99.138 46.836 102.58 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 35.885 77.17 C 35.135 77.21 35.068 76.094 35.827 76.066 C 36.577 76.018 36.643 77.149 35.885 77.17 Z M 43.882 77.59 C 43.128 77.634 43.072 76.452 43.827 76.424 C 44.583 76.374 44.637 77.569 43.882 77.59 Z M 31.909 81.99 C 31.159 82.014 31.114 80.898 31.875 80.89 C 32.625 80.862 32.669 81.986 31.909 81.99 Z M 39.894 82.59 C 39.143 82.616 39.104 81.467 39.862 81.459 C 40.614 81.429 40.652 82.585 39.894 82.59 Z M 35.13 87.375 C 34.376 87.39 34.357 86.252 35.116 86.256 C 35.868 86.239 35.887 87.38 35.129 87.375 Z M 43.106 88.077 C 42.352 88.093 42.336 86.937 43.094 86.941 C 43.848 86.923 43.863 88.083 43.106 88.077 Z M 31.017 92.137 C 30.265 92.145 30.252 91.013 31.011 91.023 C 31.762 91.013 31.776 92.149 31.017 92.137 Z M 38.987 92.911 C 38.234 92.921 38.224 91.773 38.982 91.783 C 39.735 91.773 39.745 92.923 38.987 92.911 Z M 34.139 97.608 C 33.387 97.611 33.382 96.47 34.141 96.487 C 34.893 96.481 34.897 97.625 34.139 97.607 Z M 42.103 98.436 C 41.35 98.44 41.348 97.288 42.106 97.304 C 42.859 97.298 42.861 98.454 42.103 98.436 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 24.259 74.644 C 24.316 56.227 57.294 56.229 57.349 74.645 C 57.291 93.061 24.312 93.06 24.259 74.645 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 59.012 152.715 C 67.592 132.082 64.6 110.984 49 94.625 L 56.984 87.191 C 75.229 105.921 79.356 132.923 69.141 156.764 L 59.011 152.715 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 56.968 86.81 C 75.678 106.264 79.65 132.417 69.273 157.099 L 58.693 152.835 C 67.38 132.193 64.365 110.931 48.648 94.613 L 56.968 86.811 Z M 56.999 87.57 L 49.354 94.637 C 64.727 110.691 67.825 132.327 59.33 152.592 L 69.008 156.43 C 79.013 132.539 75.035 106.432 57 87.57 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 60.596 165.695 C 62.179 163.012 58.019 160.845 56.698 163.577 L 54.459 162.36 C 58.305 155.25 61.403 147.602 62.859 139.65 L 73.593 141.617 C 72.353 148.364 70.11 154.959 67.247 161.185 C 67.179 161.568 66.548 162.13 66.966 162.428 C 69.756 163.683 71.65 167.202 69.691 170.637 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 73.887 141.373 C 72.607 148.57 70.165 155.579 67.061 162.184 C 70.048 163.52 72.011 167.252 69.784 171.001 L 60.23 165.78 C 62.118 163.281 57.844 161.018 56.836 163.926 L 54.132 162.448 C 58.12 155.062 61.202 147.481 62.655 139.364 Z M 73.3 141.859 L 63.063 139.939 C 61.583 147.741 58.563 155.283 54.787 162.271 L 56.588 163.245 C 58.119 160.65 62.298 162.794 60.935 165.593 L 69.591 170.271 C 71.316 167.023 69.367 163.8 66.851 162.662 C 66.724 162.607 66.627 162.5 66.583 162.368 C 66.555 161.669 67.214 160.86 67.423 160.185 C 68.058 158.807 68.765 157.062 69.325 155.649 C 71.028 151.218 72.41 146.529 73.3 141.859 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 70.385 168.174 L 55.433 160.497 L 55.66 160.055 L 70.613 167.731 Z M 68.691 158.514 C 67.671 158.081 66.849 156.907 67.45 155.356 C 68.052 153.801 69.457 153.489 70.507 153.857 L 70.342 154.326 C 69.156 153.911 68.252 154.662 67.914 155.535 C 67.576 156.407 67.736 157.567 68.886 158.056 Z M 65.675 87.863 L 48.405 101.595 C 44.81 95.753 39.937 90.241 34.33 85.458 C 32.413 84.162 31.14 82.288 30.555 80.189 C 29.218 75.909 31.574 70.346 36.444 69.639 C 43.831 67.972 60.414 82.378 65.674 87.863 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 105.959 68.252 C 104.899 53.522 108.398 57.474 94.86 56.243 L 93.747 67.936 C 97.793 68.323 101.904 68.423 105.959 68.252 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 94.018 67.712 C 97.899 68.068 101.799 68.169 105.693 68.013 C 105.493 65.139 105.467 62.977 105.447 61.386 C 105.419 59.068 105.405 57.905 104.922 57.371 C 104.448 56.847 103.403 56.832 101.322 56.802 C 99.832 56.781 97.806 56.752 95.084 56.512 Z M 102.137 68.582 C 99.306 68.582 96.489 68.448 93.723 68.184 C 93.657 68.178 93.597 68.145 93.555 68.094 C 93.513 68.043 93.493 67.978 93.499 67.912 L 94.613 56.219 C 94.626 56.083 94.747 55.983 94.883 55.995 C 97.71 56.252 99.802 56.283 101.329 56.305 C 105.799 56.37 105.885 56.465 105.945 61.38 C 105.965 63.012 105.992 65.246 106.207 68.234 C 106.212 68.301 106.189 68.367 106.144 68.417 C 106.1 68.468 106.036 68.498 105.969 68.5 C 104.692 68.554 103.415 68.581 102.137 68.581 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 104.897 59.477 C 106.251 59.433 107.604 59.356 108.955 59.247 C 110.167 59.221 112.003 59.03 111.869 57.493 C 111.774 56.688 110.951 56.113 110.029 56.211 C 107.894 56.408 105.755 56.531 103.619 56.571"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 104.896 59.726 C 104.758 59.728 104.645 59.619 104.643 59.481 C 104.641 59.343 104.75 59.23 104.888 59.228 C 106.243 59.183 107.597 59.107 108.949 58.998 C 109.413 58.988 110.824 58.958 111.379 58.34 C 111.571 58.125 111.651 57.855 111.621 57.515 C 111.543 56.852 110.84 56.375 110.055 56.458 C 107.916 56.657 105.771 56.778 103.624 56.82 C 103.497 56.809 103.398 56.704 103.396 56.576 C 103.393 56.448 103.487 56.339 103.614 56.323 C 105.748 56.281 107.88 56.161 110.006 55.963 C 111.056 55.852 112.005 56.525 112.116 57.464 C 112.158 57.95 112.035 58.354 111.75 58.672 C 111.125 59.368 109.865 59.476 108.968 59.496 C 107.616 59.605 106.261 59.681 104.905 59.726 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 104.896 59.477 C 106.702 59.415 108.506 59.301 110.306 59.135 C 112.584 58.917 112.904 61.814 110.582 62.058 C 108.723 62.229 106.859 62.347 104.993 62.412"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 104.993 62.661 C 104.859 62.661 104.749 62.554 104.745 62.42 C 104.743 62.354 104.767 62.29 104.812 62.242 C 104.858 62.194 104.92 62.166 104.986 62.164 C 106.847 62.099 108.706 61.981 110.561 61.81 C 111.273 61.735 112.005 61.278 111.923 60.45 C 111.864 59.868 111.34 59.286 110.33 59.382 C 108.961 59.505 106.91 59.665 104.905 59.726 C 104.839 59.729 104.774 59.705 104.726 59.66 C 104.678 59.614 104.65 59.551 104.649 59.485 C 104.647 59.419 104.671 59.355 104.716 59.307 C 104.762 59.259 104.824 59.231 104.89 59.229 C 106.691 59.167 108.49 59.053 110.284 58.887 C 111.571 58.763 112.333 59.555 112.418 60.401 C 112.5 61.218 111.969 62.162 110.609 62.305 C 108.743 62.477 106.873 62.595 105.001 62.661 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 104.993 62.413 C 106.859 62.348 108.723 62.23 110.583 62.058 C 112.934 61.833 113.253 64.73 110.86 64.982 C 108.94 65.159 107.016 65.28 105.089 65.347"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 105.089 65.596 C 104.96 65.587 104.859 65.48 104.857 65.351 C 104.855 65.221 104.952 65.111 105.081 65.098 C 107.226 65.032 109.395 64.864 110.837 64.734 C 111.892 64.623 112.311 63.948 112.253 63.368 C 112.195 62.786 111.655 62.205 110.607 62.306 C 108.742 62.477 106.873 62.596 105.001 62.661 C 104.909 62.669 104.82 62.626 104.77 62.548 C 104.72 62.471 104.717 62.372 104.762 62.291 C 104.807 62.211 104.893 62.162 104.985 62.164 C 106.846 62.099 108.705 61.981 110.56 61.81 C 111.886 61.684 112.663 62.473 112.748 63.318 C 112.83 64.135 112.282 65.082 110.886 65.228 C 109.433 65.36 107.253 65.53 105.097 65.596 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 94.978 57.535 C 95.09 53.965 96.488 52.957 99.912 53.389 C 100.378 53.406 101.317 53.422 101.783 53.42 C 103.461 53.444 104.744 54.895 104.802 56.573 C 103.961 57.018 103.113 57.448 102.256 57.861"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 94.86 56.243 C 94.984 54.95 95.16 53.293 95.16 53.293 C 97.055 53.513 98.982 53.603 100.843 53.642 C 102.731 53.397 104.745 54.473 104.801 56.542 C 103.952 56.572 103.103 56.586 102.254 56.586"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 102.254 56.835 C 102.116 56.835 102.005 56.724 102.005 56.586 C 102.005 56.448 102.116 56.337 102.254 56.337 C 103.018 56.337 103.784 56.325 104.535 56.302 C 104.466 55.655 104.148 55.06 103.65 54.642 C 102.942 54.036 101.904 53.755 100.875 53.888 C 100.863 53.89 100.85 53.89 100.838 53.89 C 98.714 53.846 96.971 53.743 95.381 53.568 C 95.327 54.08 95.201 55.285 95.108 56.267 C 95.085 56.395 94.968 56.484 94.838 56.471 C 94.709 56.459 94.611 56.35 94.612 56.22 C 94.735 54.94 94.91 53.283 94.912 53.267 C 94.919 53.201 94.952 53.141 95.004 53.099 C 95.056 53.058 95.122 53.038 95.188 53.046 C 96.825 53.236 98.618 53.346 100.83 53.392 C 101.991 53.247 103.164 53.572 103.973 54.264 C 104.645 54.829 105.038 55.658 105.049 56.536 C 105.053 56.673 104.946 56.787 104.809 56.791 C 103.971 56.819 103.112 56.834 102.254 56.835 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 101.915 56.584 C 102.844 56.589 103.854 56.575 104.779 56.543 C 106.958 56.459 106.814 53.547 104.684 53.608 C 102.63 53.674 100.574 53.659 98.521 53.562"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 102.279 56.834 L 101.914 56.833 C 101.776 56.833 101.665 56.721 101.666 56.584 C 101.666 56.446 101.777 56.335 101.915 56.335 L 101.916 56.335 C 102.848 56.34 103.862 56.325 104.77 56.295 C 105.716 56.258 106.119 55.638 106.099 55.04 C 106.079 54.451 105.631 53.83 104.691 53.857 C 102.607 53.922 100.526 53.907 98.508 53.81 C 98.417 53.809 98.333 53.759 98.29 53.678 C 98.247 53.597 98.252 53.5 98.302 53.423 C 98.353 53.347 98.441 53.305 98.532 53.313 C 100.579 53.409 102.628 53.425 104.676 53.359 C 105.904 53.325 106.568 54.169 106.596 55.023 C 106.624 55.84 106.07 56.742 104.789 56.791 C 103.99 56.819 103.112 56.834 102.279 56.834 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 105.089 65.347 C 106.849 65.482 109.386 64.667 110.904 65.409 C 111.669 65.859 111.772 66.886 111.099 67.477 C 109.594 68.522 106.997 68.039 105.185 68.282"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 105.184 68.53 C 105.058 68.522 104.958 68.422 104.95 68.297 C 104.942 68.171 105.028 68.059 105.152 68.035 C 105.752 67.955 106.415 67.951 107.118 67.948 C 108.529 67.942 109.988 67.935 110.945 67.279 C 111.197 67.053 111.324 66.739 111.293 66.415 C 111.26 66.086 111.071 65.793 110.785 65.627 C 109.9 65.199 108.59 65.341 107.322 65.477 C 106.514 65.564 105.751 65.647 105.07 65.594 C 104.933 65.584 104.831 65.464 104.841 65.327 C 104.851 65.19 104.971 65.088 105.108 65.098 C 105.743 65.147 106.484 65.067 107.269 64.982 C 108.611 64.838 109.998 64.688 111.013 65.185 L 111.03 65.194 C 111.466 65.45 111.742 65.878 111.789 66.368 C 111.835 66.869 111.63 67.36 111.241 67.68 C 110.157 68.432 108.613 68.439 107.12 68.446 C 106.434 68.449 105.786 68.452 105.218 68.528 C 105.207 68.529 105.195 68.53 105.184 68.53 Z M 93.896 69.27 L 95.503 51.811 C 84.496 50.834 73.713 47.053 64.683 40.661 C 62.504 39.13 60.436 37.447 58.493 35.625 C 55.476 33.088 51.038 34.702 47.825 36.993 C 44.086 39.676 43.096 45.174 46.472 48.388 C 49.014 50.776 51.722 52.981 54.575 54.988 C 66.112 63.152 79.86 68.016 93.896 69.27 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 61.874 73.919 C 61.934 74.568 61.719 75.212 61.28 75.694 C 60.841 76.176 60.22 76.451 59.568 76.451 L 22.78 76.451 C 21.578 76.452 20.561 75.562 20.402 74.37 L 22.306 41.065 C 21.775 37.075 24.879 33.528 28.905 33.528 L 52.965 33.528 C 58.181 33.568 62.292 38.235 61.862 43.425 L 61.873 73.919 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 51.045 33.528 C 48.033 20.844 25.638 29.811 40.181 40.398 C 40.442 40.611 40.821 40.59 41.059 40.352 L 42.784 38.628 L 44.504 36.908 L 47.848 40.158 C 49.116 41.465 51.148 35.142 51.045 33.528 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 43.874 41.26 L 42.108 39.494 L 44.566 37.037 L 47.023 39.494 L 45.251 41.266 L 46.977 46.499 L 51.044 33.529 L 35.358 33.529 L 43.19 46.589 C 43.193 46.579 43.87 41.269 43.874 41.261 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 47.32 62.356 L 45.252 41.266 L 47.024 39.494 L 44.566 37.037 L 42.109 39.494 L 43.875 41.261 C 43.873 41.258 41.046 62.444 41.045 62.445 C 44.725 66.93 43.974 67.688 47.319 62.355 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 40.719 40.661 C 40.499 40.661 40.239 40.581 39.936 40.422 C 38.055 39.431 34.873 35.487 35.174 33.5 C 35.189 33.398 35.285 33.328 35.387 33.343 C 35.489 33.358 35.559 33.454 35.544 33.556 C 35.423 34.35 35.988 35.688 37.054 37.136 C 38.021 38.45 39.249 39.638 40.11 40.092 C 40.338 40.212 40.747 40.388 40.931 40.216 L 42.928 38.219 L 44.371 36.777 C 44.443 36.705 44.56 36.704 44.633 36.775 L 47.977 40.025 C 48.102 40.152 48.214 40.178 48.357 40.115 C 49.437 39.637 50.949 34.973 50.858 33.54 C 50.852 33.437 50.93 33.348 51.033 33.342 C 51.136 33.336 51.225 33.414 51.231 33.517 C 51.323 34.971 49.805 39.882 48.509 40.456 C 48.223 40.582 47.941 40.522 47.713 40.288 L 44.505 37.171 L 43.192 38.483 L 41.191 40.485 C 41.063 40.603 40.893 40.666 40.719 40.661 Z M 48.876 45.632 C 48.919 46.794 48.886 47.833 48.858 48.756 C 48.796 50.784 48.751 52.249 49.598 53.066 C 50.419 53.856 52.081 54.105 55.156 53.895 L 55.166 53.895 C 55.97 53.88 56.574 53.645 57.013 53.177 C 58.069 52.049 57.959 49.788 57.861 47.794 C 57.823 46.998 57.786 46.241 57.831 45.632 Z M 53.319 54.338 C 51.279 54.338 50.049 54.018 49.339 53.335 C 48.372 52.403 48.419 50.868 48.485 48.745 C 48.515 47.777 48.549 46.681 48.495 45.454 L 48.487 45.259 L 58.247 45.259 L 58.222 45.467 C 58.152 46.077 58.192 46.902 58.234 47.776 C 58.335 49.844 58.45 52.188 57.285 53.432 C 56.782 53.97 56.072 54.252 55.177 54.267 C 54.494 54.314 53.877 54.337 53.319 54.337 Z M 44.244 76.45 L 44.617 76.45 L 44.617 66.238 L 44.244 66.238 L 44.244 76.452 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 41.24 86.526 C 44.28 71.771 47.876 75.272 34.117 70.966 C 30.114 85.258 26.921 82.469 38.236 85.738 C 37.235 85.466 36.234 85.188 35.233 84.898 C 34.159 88.107 40.544 89.846 41.239 86.526 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 35.404 85.211 C 35.233 86.005 35.594 86.598 35.946 86.966 C 36.799 87.858 38.395 88.291 39.58 87.952 C 40.117 87.799 40.798 87.418 40.995 86.475 C 41.715 82.979 42.465 80.511 43.013 78.71 C 43.667 76.555 44.028 75.368 43.641 74.659 C 43.207 73.863 41.725 73.442 38.771 72.602 C 37.561 72.257 36.066 71.832 34.287 71.279 C 33.297 74.775 32.369 77.209 31.687 78.999 C 30.895 81.079 30.457 82.226 30.787 82.869 C 31.137 83.555 32.437 83.903 35.027 84.595 L 35.228 84.649 C 35.253 84.649 35.278 84.652 35.302 84.659 L 35.822 84.809 C 36.562 85.008 37.38 85.232 38.304 85.499 C 38.43 85.541 38.501 85.674 38.465 85.802 C 38.429 85.93 38.299 86.007 38.17 85.978 C 37.24 85.725 36.437 85.501 35.688 85.288 Z M 38.703 88.566 C 37.559 88.566 36.342 88.099 35.587 87.31 C 34.974 86.669 34.743 85.887 34.923 85.082 L 34.898 85.076 C 32.081 84.323 30.795 83.979 30.344 83.096 C 29.914 82.256 30.351 81.108 31.223 78.821 C 31.917 77 32.867 74.505 33.877 70.899 C 33.895 70.834 33.939 70.78 33.998 70.748 C 34.057 70.716 34.127 70.709 34.191 70.729 C 35.757 71.215 37.329 71.68 38.907 72.123 C 42.091 73.029 43.544 73.443 44.077 74.42 C 44.565 75.313 44.205 76.496 43.489 78.854 C 42.944 80.647 42.199 83.102 41.483 86.576 C 41.289 87.502 40.661 88.161 39.716 88.431 C 39.396 88.522 39.053 88.566 38.703 88.566 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 29.532 85.782 L 34.969 69.112 C 29.6 67.24 21.907 64.724 18.143 60.464 C 18.143 60.464 18.148 60.462 18.143 60.465 C 18.145 60.464 18.145 60.465 18.143 60.465 C 18.143 60.465 18.145 60.465 18.143 60.465 C 18.147 60.466 18.141 60.465 18.143 60.466 C 18.139 60.464 18.147 60.468 18.144 60.466 C 18.14 60.463 18.152 60.454 18.154 60.448 C 20.264 57.196 23.899 55.084 27.312 53.033 C 28.853 52.16 30.424 51.341 32.022 50.577 C 36.145 48.565 37.877 43.582 36.326 39.225 C 34.609 34.437 29.027 32.536 24.434 34.771 C 20.426 36.706 16.599 38.811 12.89 41.438 C 1.622 48.964 -4.707 62.058 6.017 73.218 C 12.448 79.663 21.127 82.928 29.532 85.782 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 52.616 21.857 C 52.616 22.904 51.768 23.752 50.721 23.752 C 49.674 23.752 48.826 22.904 48.826 21.857 C 48.826 20.81 49.674 19.962 50.721 19.962 C 51.768 19.962 52.616 20.81 52.616 21.857 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 50.72 20.21 C 49.813 20.21 49.075 20.949 49.075 21.856 C 49.075 22.764 49.813 23.503 50.721 23.503 C 51.628 23.503 52.367 22.764 52.367 21.857 C 52.367 20.949 51.628 20.211 50.721 20.211 Z M 50.72 24 C 49.537 23.998 48.579 23.04 48.577 21.857 C 48.577 20.674 49.539 19.713 50.721 19.713 C 51.903 19.713 52.864 20.674 52.864 21.856 C 52.863 23.039 51.904 23.998 50.721 24 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 44.364 34.233 C 42.006 34.233 39.832 32.424 39.832 30.265 L 41.836 22.844 C 41.836 20.684 41.26 18.934 43.618 18.934 L 46.105 18.934 C 48.464 18.934 47.887 20.684 47.887 22.844 L 47.887 30.324 C 47.887 32.483 46.722 34.233 44.364 34.233 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 40.08 30.297 C 40.102 32.265 42.094 33.984 44.364 33.984 C 46.778 33.984 47.638 32.093 47.638 30.324 L 47.638 22.844 C 47.638 22.464 47.655 22.104 47.672 21.756 C 47.718 20.797 47.757 19.969 47.362 19.555 C 47.123 19.305 46.712 19.183 46.105 19.183 L 43.618 19.183 C 43.01 19.183 42.599 19.305 42.361 19.555 C 41.966 19.969 42.005 20.797 42.051 21.755 C 42.067 22.105 42.084 22.465 42.084 22.844 L 42.084 22.877 L 42.076 22.909 L 40.081 30.298 Z M 44.364 34.481 C 41.817 34.481 39.583 32.511 39.583 30.265 L 39.583 30.232 L 41.587 22.811 C 41.584 22.467 41.573 22.123 41.554 21.779 C 41.502 20.707 41.458 19.78 42.001 19.211 C 42.338 18.858 42.867 18.686 43.618 18.686 L 46.105 18.686 C 46.855 18.686 47.385 18.858 47.722 19.211 C 48.264 19.781 48.22 20.707 48.169 21.78 C 48.153 22.122 48.136 22.477 48.136 22.844 L 48.136 30.324 C 48.136 32.849 46.656 34.481 44.364 34.481 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 45.807 27.756 C 43.636 27.757 41.672 26.466 40.812 24.473 L 40.732 24.286 C 40.156 22.963 40.133 21.464 40.668 20.124 C 41.197 18.781 42.241 17.706 43.567 17.137 C 44.891 16.565 46.389 16.542 47.73 17.074 C 49.07 17.606 50.145 18.649 50.717 19.973 L 50.797 20.16 C 51.373 21.483 51.396 22.982 50.86 24.322 C 50.332 25.665 49.288 26.74 47.962 27.309 C 47.282 27.604 46.548 27.756 45.807 27.756 Z"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 45.721 16.44 C 44.946 16.44 44.179 16.599 43.468 16.908 C 42.081 17.503 40.99 18.628 40.437 20.032 C 39.877 21.434 39.9 23.001 40.503 24.385 L 40.583 24.572 C 41.181 25.957 42.305 27.048 43.707 27.604 C 45.11 28.16 46.675 28.136 48.06 27.537 C 50.94 26.288 52.266 22.944 51.025 20.061 L 50.945 19.873 C 50.045 17.788 47.992 16.439 45.721 16.44 Z M 45.721 16.938 C 47.793 16.937 49.667 18.169 50.488 20.071 L 50.568 20.258 C 51.704 22.889 50.493 25.943 47.863 27.08 C 45.232 28.216 42.178 27.005 41.041 24.374 L 40.959 24.187 C 39.823 21.556 41.034 18.502 43.665 17.365 C 44.314 17.084 45.014 16.938 45.721 16.938 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 40.713 23.752 C 39.991 23.75 39.333 23.339 39.015 22.69 C 38.698 22.042 38.777 21.269 39.218 20.698 C 39.66 20.127 40.388 19.856 41.095 20"
        fill="rgb(255,255,255)"
      ></path>
      <path
        d="M 40.713 24 C 39.53 23.999 38.571 23.04 38.569 21.857 C 38.57 21.213 38.86 20.603 39.359 20.196 C 39.858 19.789 40.514 19.628 41.145 19.757 C 41.272 19.791 41.351 19.918 41.325 20.048 C 41.298 20.177 41.176 20.263 41.045 20.244 C 40.56 20.145 40.057 20.269 39.673 20.581 C 39.29 20.894 39.067 21.362 39.067 21.857 C 39.067 22.764 39.805 23.503 40.713 23.503 C 40.844 23.511 40.946 23.62 40.946 23.751 C 40.946 23.883 40.844 23.992 40.713 24 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 46.543 24.011 L 47.483 24.011 C 47.743 24.011 47.935 23.634 47.877 23.238 L 47.48 20.534 C 47.389 19.907 46.826 19.872 46.702 20.486 L 46.158 23.19 C 46.076 23.597 46.27 24.011 46.543 24.011 Z"
        fill="rgb(41,69,255)"
      ></path>
      <path
        d="M 41.114 19.037 C 41.492 19.037 41.798 19.391 41.798 19.827 L 41.798 23.374 C 41.798 23.796 41.502 24.137 41.137 24.137 L 40.917 24.137 C 40.647 24.137 40.43 23.885 40.43 23.575 L 40.43 19.827 C 40.43 19.391 40.736 19.037 41.114 19.037 Z M 50.692 19.037 C 51.062 19.037 51.362 19.375 51.376 19.797 L 51.376 23.32 C 51.376 23.762 51.073 24.12 50.695 24.137 L 50.643 24.137 C 50.299 24.122 50.022 23.802 50.008 23.404 L 50.008 19.827 C 50.008 19.391 50.314 19.037 50.692 19.037 Z M 48.395 24.51 C 48.467 24.51 48.536 24.541 48.583 24.596 C 48.63 24.65 48.651 24.723 48.641 24.794 C 48.596 25.112 48.401 25.602 47.971 25.941 L 47.971 26.275 C 47.971 26.871 47.486 27.357 46.889 27.357 C 46.292 27.356 45.808 26.872 45.807 26.275 L 45.807 26.093 C 45.215 25.764 44.962 25.163 44.91 24.793 C 44.901 24.722 44.922 24.65 44.969 24.596 C 45.016 24.542 45.084 24.511 45.156 24.51 Z M 47.445 25.505 L 46.275 25.505 L 46.275 26.214 C 46.275 26.537 46.537 26.799 46.86 26.799 C 47.183 26.799 47.445 26.537 47.445 26.214 L 47.445 25.504 Z M 44.41 21.521 C 44.301 21.522 44.204 21.452 44.171 21.348 C 44.138 21.244 44.176 21.131 44.266 21.069 L 45.063 20.503 L 44.275 19.993 C 44.196 19.947 44.15 19.861 44.153 19.77 C 44.156 19.678 44.209 19.596 44.291 19.556 C 44.373 19.515 44.47 19.523 44.545 19.575 L 45.641 20.284 C 45.71 20.329 45.752 20.405 45.754 20.487 C 45.756 20.569 45.717 20.647 45.65 20.695 L 44.554 21.475 C 44.512 21.505 44.462 21.521 44.41 21.521 Z M 49.027 21.52 C 48.977 21.52 48.927 21.505 48.883 21.474 L 47.787 20.694 C 47.72 20.646 47.682 20.568 47.684 20.486 C 47.686 20.404 47.728 20.328 47.797 20.283 L 48.892 19.574 C 49.007 19.506 49.155 19.541 49.228 19.653 C 49.301 19.766 49.271 19.915 49.162 19.992 L 48.374 20.502 L 49.171 21.069 C 49.258 21.132 49.295 21.244 49.263 21.347 C 49.23 21.449 49.135 21.519 49.027 21.52 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 49.41 18.29 C 50.573 18.29 51.52 19.237 51.52 20.4 C 51.52 21.564 50.573 22.51 49.41 22.51 C 48.814 22.509 48.246 22.257 47.846 21.816 C 47.446 21.374 47.252 20.784 47.31 20.191 C 47.197 20.106 47.043 20.101 46.925 20.178 C 46.987 20.773 46.795 21.366 46.395 21.811 C 45.995 22.255 45.425 22.509 44.827 22.51 C 43.664 22.509 42.721 21.568 42.717 20.405 L 41.3 20.405 C 41.207 20.411 41.119 20.365 41.071 20.286 C 41.023 20.206 41.023 20.107 41.071 20.027 C 41.119 19.948 41.207 19.902 41.3 19.908 L 42.775 19.908 C 43.004 18.96 43.852 18.291 44.827 18.29 C 45.739 18.29 46.519 18.872 46.812 19.685 C 47.009 19.607 47.228 19.607 47.424 19.687 C 47.726 18.85 48.52 18.291 49.41 18.29 Z M 44.827 18.788 C 43.959 18.819 43.271 19.531 43.271 20.4 C 43.271 21.269 43.959 21.981 44.827 22.012 C 45.695 21.981 46.383 21.269 46.383 20.4 C 46.383 19.531 45.695 18.819 44.827 18.788 Z M 49.41 18.788 C 48.542 18.819 47.854 19.531 47.854 20.4 C 47.854 21.269 48.542 21.981 49.41 22.012 C 50.278 21.981 50.966 21.269 50.966 20.4 C 50.966 19.531 50.278 18.819 49.41 18.788 Z"
        fill="rgb(41,69,255)"
      ></path>
    </svg>
  )
}

DoctorPrescription.defaultProps = {
  $size: 182,
}
