import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Log } from "../../../../utils"

export function CleanUpTest() {
  // useEffect(() => {
  //   Log.info(`cleanUpTest/mount!`)

  //   return () => {
  //     Log.info(`cleanUpTest/un mount!`)
  //   }
  // }, [])
  return <div>CleanUpTest</div>
}

CleanUpTest.defaultProps = {}
