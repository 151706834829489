import * as React from "react"
import { Grid } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import { sizeBg, DesktopView, MobileView } from "../../../../utils"
import { SECTION_SUMMARY_CLASSNAME } from "../../../../constants"

export function SectionSummary({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return (
    <Grid className={SECTION_SUMMARY_CLASSNAME} {...sharedProps}>
      <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      <MobileView>
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      </MobileView>
      {children}
      <DesktopView>
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />
      </DesktopView>
    </Grid>
  )
}

SectionSummary.defaultProps = {}
