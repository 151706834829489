"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = RootLayout;

var _react = _interopRequireDefault(require("react"));

var _recoil = require("recoil");

var _lighthouse = require("@harborschool/lighthouse");

function RootLayout(_ref) {
  var children = _ref.children,
      locale = _ref.locale;
  var primitives = {
    primaryFontFamily: "system-ui, Helvetica, Arial, sans-serif"
  };
  var koTheme = (0, _lighthouse.createTheme)(primitives);
  return _react.default.createElement(_recoil.RecoilRoot, null, _react.default.createElement(_lighthouse.LighthouseProvider, {
    theme: locale === "ko" ? koTheme : _lighthouse.LightTheme
  }, children));
}