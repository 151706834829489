import { isClient } from "./../../utils"
import firebaseConfig from "../../../firebase-config"

export async function loadAuth() {
  const firebase = await import("firebase/app")
  await import("firebase/auth")

  try {
    isClient &&
      !firebase.apps.length &&
      firebase.initializeApp({
        databaseURL: firebaseConfig.databaseURL,
      })
  } catch (error) {
    /*
     * We skip the "already exists" message which is
     * not an actual error when we're hot-reloading.
     */
    if (!/already exists/.test(error.message)) {
      console.error("Firebase initialization error", error.stack)
    }
  }

  return firebase.auth()
}
