import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"

export function Heart({ width }) {
  const theme = useTheme()

  return (
    <System.Icon viewBox="0 0 361 466" width={width}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M307.321 137.106L342.678 119.796L360.002 155.126L324.603 172.436L289.245 189.747L271.922 154.375L254.598 119.045L289.997 101.735L307.321 137.106ZM54.017 134.353L74.138 100.567L107.992 120.672L87.83 154.5L67.667 188.328L33.855 168.182L0 148.035L20.162 114.207L54.017 134.353ZM150.863 29.49L176.954 0L206.467 26.07L180.377 55.56L154.287 85.05L124.773 58.98L95.26 32.911L121.35 3.42L150.863 29.49ZM287.283 260.114L338.796 311.586L338.754 311.628L338.796 311.67L287.283 363.142L235.771 414.614H235.688L184.259 465.962L132.872 414.614H132.747L81.276 363.142L29.764 311.67L81.276 260.198L132.747 208.725L184.259 260.198L235.771 208.725L287.242 260.156L287.283 260.114ZM163.428 313.38C158.818 313.38 155.08 309.645 155.08 305.038C155.08 300.43 158.818 296.695 163.428 296.695C168.039 296.695 171.777 300.43 171.777 305.038C171.777 309.645 168.039 313.38 163.428 313.38ZM184.259 392.924C161.252 392.81 142.629 374.202 142.515 351.213H154.12C154.725 367.512 168.124 380.413 184.447 380.413C200.77 380.413 214.169 367.512 214.774 351.213H226.337C226.337 362.333 221.893 372.994 213.993 380.826C206.092 388.658 195.388 393.013 184.259 392.924ZM201.416 313.38C196.805 313.38 193.067 309.645 193.067 305.038C193.067 300.43 196.805 296.695 201.416 296.695C206.027 296.695 209.765 300.43 209.765 305.038C209.765 309.645 206.027 313.38 201.416 313.38Z"
        fill={theme.colors.contentInversePrimary}
      />
    </System.Icon>
  )
}

Heart.defaultProps = {
  width: 100,
}
