import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"

export function SectionTitle({ children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  return (
    <>
      <System.HeadingMedium
        overrides={{
          Block: {
            color: theme.colors.primary,
          },
        }}
      >
        {children}
      </System.HeadingMedium>
      <System.Block height={theme.sizing.scale700} />
    </>
  )
}

SectionTitle.defaultProps = {}
