import * as React from "react"
import { useRef } from "react"
import { Log } from "../../../utils"
import { useTheme } from "@harborschool/lighthouse"
import { useVeMap, useVeUpdateByScroll } from "../../../hooks/post-contents"

export function VideoExplorer({ children, videoMarkersPath, $blockContent }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const ref = useRef(null)
  const veMap = useVeMap(ref, videoMarkersPath, $blockContent)
  useVeUpdateByScroll(veMap)
  Log.info(`VideoExplorer/veMap`, veMap)

  return <div ref={ref}>{renderWithAccessibility(children, $blockContent)}</div>
}

VideoExplorer.defaultProps = {}

function renderWithAccessibility(children, blockContent) {
  if (blockContent) {
    const newChildren = []
    newChildren[0] = children[0]
    return newChildren
  }
  return children
}
