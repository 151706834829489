import { loadFireStore } from "../../handlers"

export const addUser = async ({ email, uid }) => {
  try {
    const doc: any = await loadFireStore("user", uid)

    const basicInfo = {
      email,
      name: email.split("@")[0], // default name is email
      date: new Date().getTime(),
    }
    await doc.set({ ...basicInfo })
    return basicInfo
  } catch (error) {
    console.error("error - updateUser", error)
    alert("Error (add-user)")
  }
}
