import { isClient } from "../../utils"
import firebaseConfig from "../../../firebase-config"

export async function loadFirebase() {
  const firebase = await import("firebase/app")
  try {
    isClient && !firebase.apps.length && firebase.initializeApp(firebaseConfig)
  } catch (error) {
    /*
     * We skip the "already exists" message which is
     * not an actual error when we're hot-reloading.
     */
    if (!/already exists/.test(error.message)) {
      console.error("Firebase initialization error", error.stack)
    }
  }
  return firebase
}
