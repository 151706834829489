import * as React from "react"
import { LanguageTabWrap } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"

export function LanguageTab({ language }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <LanguageTabWrap $language={language} {...sharedProps}>
      <System.LabelXSmall color="contentInversePrimary">
        {language}
      </System.LabelXSmall>
    </LanguageTabWrap>
  )
}

LanguageTab.defaultProps = {}
