import { loadFireStore } from "../../handlers"

export const addNotificationQue = async ({ comment }) => {
  const firebase = await import("firebase/app")

  try {
    // add notification que to userDB
    const doc = await loadFireStore("comments", comment.pId)
    const snapshot = await doc.get()
    const data = await snapshot.data()

    const targetUser = data.userId
    if (comment.userId !== targetUser) {
      const userDoc = await loadFireStore("user", targetUser)
      const notifications = {
        notifications: firebase.firestore.FieldValue.arrayUnion({
          kind: "comment",
          ...comment,
        }),
      }
      await userDoc.update({
        ...notifications,
      })
    }
  } catch (error) {
    console.error("error - addNotificationQue", error)
    alert("Error (addNotificationQue)")
  }
}
