import * as React from "react"
import Markdown, { compiler } from "markdown-to-jsx"

export function MarkdownText({ children }) {
  return <>{handleMarkdownTexts(children)}</>
}

MarkdownText.defaultProps = {}

function handleMarkdownTexts(text) {
  let content = Array.isArray(text) ? text.join("") : text
  return typeof content === "string"
    ? compiler(content, { forceInline: true })
    : content
}
