import { styled } from "styletron-react"

// Code
export const Pre = styled("pre", ({ $theme }) => {
  return {
    textAlign: "left",
    margin: "1em 0",
    padding: "1.7em 1em 1em 1em",
    overflow: "scroll",
    lineHeight: "1.5",
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  }
})
export const Line = styled("div", ({ $theme }) => {
  return {
    display: "table-row",
  }
})
export const LineContent = styled("span", ({ $theme }) => {
  return {
    display: "table-cell",
  }
})

// Tabs
export const LanguageTabWrap = styled("div", ({ $theme, $language }) => {
  return {
    borderRadius: "0 0 0.25rem 0.25rem",
    letterSpacing: "0.025rem",
    padding: "0.1rem 0.5rem",
    textAlign: "right",
    textTransform: "uppercase",
    position: "absolute",
    left: "1rem",
    top: "0",
    ...getLanguageStyle({ $theme, $language }),
  }
})

export const CopyTabWrap = styled("button", ({ $theme }) => {
  return {
    background: "unset",
    border: "unset",
    outline: "unset",
    textTransform: "uppercase",
    padding: "0.5rem 0.8rem",
    position: "absolute",
    right: "0px",
    top: "0px",
    cursor: "pointer",
  }
})

function getLanguageStyle({ $theme, $language }) {
  switch ($language) {
    case "html":
      return {
        background: "#005a9c",
      }
    case "css":
      return {
        background: "#ff9800",
      }
    case "js":
      return {
        background: "#f7df1e",
      }

    default:
      return {
        background: $theme.colors.backgroundInverseSecondary,
      }
  }
}
