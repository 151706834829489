import * as React from "react"
import { Log } from "../../utils"
import Highlight, { defaultProps } from "prism-react-renderer"
import * as System from "@harborschool/lighthouse"
import vsDark from "prism-react-renderer/themes/vsDark"
import { LanguageTab } from "./language-tab"
import { CopyTab } from "./copy-tab"
import { Pre, Line, LineContent } from "./styled-components"

export const Code = ({ children }) => {
  const target = children.props.children
  const className = target.props.className
  const code = target.props.children

  const language = className.replace(/language-/, "").toLowerCase()
  const codeString = code.replace(/[\r\n]+$/, "").trim()

  return (
    <System.Block position="relative">
      <Highlight
        {...defaultProps}
        theme={vsDark}
        // theme={isDarkTheme({ theme }) ? prismThemeDark : prismThemeLight}
        code={codeString}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <Line key={i} {...getLineProps({ line, key: i })}>
                {/* <LineNo>{i + 1}</LineNo> */}
                <LineContent>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </LineContent>
              </Line>
            ))}
          </Pre>
        )}
      </Highlight>
      <LanguageTab language={language} />
      <CopyTab codeString={codeString} />
    </System.Block>
  )
}
