import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  CANVAS_DEFAULT_FPS,
  CANVAS_RESOLUTION,
  VIDEO_RATIO,
} from "../../../../constants"
import { FrameUnpacker, CanvasFrameScrubber } from "../../../../handlers"
import {
  veFramesState,
  veInfoState,
  veSectionState,
  veState,
} from "../../../../store"
import { isClient, Log } from "../../../../utils"
import { useAsync } from "../../../../hooks"
import {
  FrameUnpackProgress,
  SectionDetailExplorerWrap,
  StickyWrap,
} from "./styled-components"

function Sticky({ percent, children }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <StickyWrap {...sharedProps}>
      {children}
      <System.Block
        backgroundColor="primary"
        height="scale300"
        position="absolute"
        bottom="0"
        width={`${percent}%`}
        overrides={{
          Block: {
            borderRadius: "1000px",
          },
        }}
      />
    </StickyWrap>
  )
}

export function SectionDetailCanvas({
  sectionNumber,
  src,
  videoFramesPath,
  canvasVideoFPS,
}) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [veFrames, setVeFrames] = useRecoilState(veFramesState)
  const veSection = useRecoilValue(veSectionState)
  // const { videoDuration } = useRecoilValue(veInfoState)
  const [{ currentVideoTime }, setCurrentVideoTime] = useRecoilState(veState)
  const [{ videoDuration }, setVeInfo] = useRecoilState(veInfoState)
  const percent = (currentVideoTime / videoDuration) * 100
  // Log.info(`cleanUpTest/videoDuration - before useEffect`, videoDuration)

  const [duration, setDuration] = useState(0)

  const canvasRef = useRef()
  const [observer, setObserver] = useState<any>()

  const [progress, setProgress] = useState(0)

  const { execute, loading, data, error } = useAsync({
    asyncFunc: async () => {
      const options = {
        urlPattern: `${videoFramesPath}/image{{id}}.jpg`,
        start: 1,
        end:
          duration *
          ((canvasVideoFPS !== "None" && canvasVideoFPS) || CANVAS_DEFAULT_FPS),
        padding: 3,
        progress: value => {
          // updateProgress(framesProgress, value);
          // setProgress(value)
        },
      }

      Log.info(`cleanUpTest/Initializing frames download...`)
      // Log.info(`duration/videoDuration`, videoDuration)
      // Log.info(`duration/duration`, duration)
      Log.info(
        `flow/Please be patient. Downloaing ${options.end} from ${videoFramesPath} frames...`
      )

      const startTime = Date.now()

      const unpacked = await FrameUnpacker.unpack(options)

      const endTime = Date.now()

      Log.warn(
        `flow/Took ${
          (endTime - startTime) / 1000
        } seconds for ${videoFramesPath} frames.`
      )

      setVeFrames(unpacked)

      return frames
    },
    immediate: false,
    funcParams: { data: "1" },
    initialData: [],
  })

  // useEffect(() => {
  //   Log.info(`cleanUpTest/mount!`)
  //   Log.info(`cleanUpTest/videoDuration`, videoDuration)

  //   return () => {
  //     Log.info(`cleanUpTest/un mount!`)
  //   }
  // }, [videoDuration])

  useEffect(() => {
    Log.info(`duration/mount!`)

    Log.info(`duration/videoDuration (mount)`, videoDuration)
    // Log.info(`duration/duration (mount)`, duration)
    setDuration(videoDuration)

    return () => {
      Log.info(`duration/UNMOUNT!`)
    }
  }, [videoDuration])

  useEffect(() => {
    Log.info(`duration/videoDuration`, videoDuration)
    Log.info(`duration/duration`, duration)
  }, [duration])

  useEffect(() => {
    Log.info(`cleanUpTest/mount!`)
    Log.info(`cleanUpTest/videoDuration`, videoDuration)

    return () => {
      Log.info(`flow/UNMOUNT! - setVeFrames([])`)
      setVeFrames([])
      setVeInfo({ videoDuration: 0 })
      setCurrentVideoTime({ currentVideoTime: 0 })
      // setObserver(null)
    }
  }, [])

  // unpack frames (only once)
  useEffect(() => {
    let mounted = true
    // let unpacker

    Log.info(`duration/videoDuration - unpack frames`, videoDuration)
    Log.info(`duration/duration - unpack frames`, duration)

    async function update() {
      if (
        sectionNumber === 0 &&
        veFrames.length === 0 &&
        videoDuration > 0 &&
        duration > 0
      ) {
        execute()
      }
    }

    if (mounted) update()

    return () => {
      mounted = false
      // Log.info(`axios/cancelV`, cancelV)
      // if (cancelV) cancelV()
      // if (unpacker) unpacker.cancel()
    }
  }, [duration])

  useEffect(() => {
    if (data.length) {
      Log.info(`flow/observer for 0 section`)

      newObserver({ canvasRef, setObserver, frameData: data })

      // share frames to other SectionDetailCanvas
      // setVeFrames(data)
    }
  }, [data])

  useEffect(() => {
    Log.info(`flow/observer for other sections - ${sectionNumber}`)
    Log.info(`flow/veFrames.length`, veFrames.length)
    newObserver({ canvasRef, setObserver, frameData: veFrames })
  }, [veFrames])

  // Log.info(`observer/observer - sectionNumber : ${sectionNumber}`, observer)

  useEffect(() => {
    const isActive = sectionNumber === veSection.currentSection

    if (isActive && videoDuration > 0 && duration > 0 && observer)
      observer.next(percent)
  }, [percent, videoDuration, duration, observer])

  return (
    <SectionDetailExplorerWrap {...sharedProps}>
      {isClient && (
        <Sticky percent={percent}>
          {sectionNumber === 0 && loading && (
            <FrameUnpackProgress {...sharedProps}>
              Loading...!!
            </FrameUnpackProgress>
          )}
          {/* {sectionNumber === 0 && progress < 100 && (
            <FrameUnpackProgress {...sharedProps}>
              Loading... {progress}%
            </FrameUnpackProgress>
          )} */}
          <canvas
            width={CANVAS_RESOLUTION / VIDEO_RATIO}
            height={CANVAS_RESOLUTION}
            style={{
              width: "100%",
              height: "100%",
            }}
            ref={canvasRef}
          />
        </Sticky>
      )}
    </SectionDetailExplorerWrap>
  )
}

SectionDetailCanvas.defaultProps = {}

// async function getFrameData({ urlPattern, start, end, padding, progress }) {
//   Log.info(`CanvasVideo/Initializing frames download...`)
//   Log.info(`CanvasVideo/Please be patient. Downloaing ${end} frames...`)

//   const startTime = Date.now()
//   const unpacker = FrameUnpacker
//   const frames = await unpacker.unpack({
//     urlPattern,
//     start,
//     end,
//     padding,
//     progress,
//   })
//   const endTime = Date.now()

//   Log.warn(`CanvasVideo/Took ${(endTime - startTime) / 1000} seconds.`)
//   return { frames, cancel: unpacker.cancel, cancels: unpacker.cancels }
// }

function newObserver({ canvasRef, setObserver, frameData }) {
  Log.info(`useAsync/newObserver!!!!!`)
  Log.info(`useAsync/frameData.length`, frameData.length)
  if (frameData.length > 0) {
    const canvas: any = canvasRef.current
    Log.info(`useAsync/canvas`, canvas)
    if (canvas) {
      const ctx = canvas.getContext("2d")
      setObserver(CanvasFrameScrubber.create(ctx, frameData))
    }
  }
  // else setObserver(null)
}
