import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"

export function Done({ width }) {
  const theme = useTheme()

  return (
    <System.Icon viewBox="0 0 261 414" width={width}>
      <path
        d="M 1 326.633 L 44.211 369.794 L 87.422 412.954 L 87.422 326.633 Z"
        fill="rgb(168,209,255)"
      ></path>
      <path
        d="M 173.798 240.809 L 130.587 197.693 L 87.42 154.533 L 87.42 240.809 L 0.998 240.809 L 0.998 327.129 L 87.42 327.129 L 87.42 413.45 L 260.22 413.45 L 260.22 240.809 Z M 172.209 0.496 L 128.998 43.656 L 85.831 0.496 L 42.62 43.656 L 85.831 86.816 L 128.998 129.933 L 172.209 86.816 L 215.42 43.656 Z"
        fill={theme.colors.contentInversePrimary}
      ></path>
    </System.Icon>
  )
}

Done.defaultProps = {
  width: 100,
}
