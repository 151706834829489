// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-home-home-tsx": () => import("./../../../src/pages/home/home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-home-index-ts": () => import("./../../../src/pages/home/index.ts" /* webpackChunkName: "component---src-pages-home-index-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-post-layout-post-layout-tsx": () => import("./../../../src/templates/post-layout/post-layout.tsx" /* webpackChunkName: "component---src-templates-post-layout-post-layout-tsx" */)
}

