import { MembershipT } from "./types"
import { isClient } from "./utils"

export const STRIPE_API_KEY = "pk_live_bavPcuL9aoMmlNGm3Y07ybjN"
export const STRIPE_SERVERLESS_API_BASE =
  "https://zavfr9sqz9.execute-api.us-west-2.amazonaws.com/prod"
// "http://localhost:3001"
export const MEMBERSHIP: {
  yearly: MembershipT
  monthly: MembershipT
  test: MembershipT
} = {
  yearly: {
    key: "yearly",
    plan: "price_1HswgsKeWJo6vx08H6OVNgMm",
    price: 11880,
    sale_price: 2280, // $1.9 * 12
    interval: "year",
  },
  monthly: {
    key: "monthly",
    plan: "price_1HswgtKeWJo6vx08Rc1q8t2a",
    price: 1490,
    sale_price: 290,
    interval: "month",
  },
  test: {
    key: "test",
    plan: "price_1Hswi7KeWJo6vx08ERU7Q87v",
    price: 70,
    interval: "year",
  },
}

export const FIRESTORE_BLOG_VIEWS_API_BASE =
  "https://harborschool-blog-views.vercel.app/api"
// url from Embedded forms https://us16.admin.mailchimp.com/lists/integration/embeddedcode?id=80899
// update confirmation email design at here https://us16.admin.mailchimp.com/lists/designer/?id=80899
export const MAILCHIMP_FORM =
  "https://school.us16.list-manage.com/subscribe/post?u=ec7a9066b2c1de4e7f9961426&amp;id=fbc817177b"

/**
 * Courses
 */
export const COURSES = [
  {
    title: "Modern HTML and CSS Basics",
    key: "modern-html-css",
    excerpt: "Learn modern HTML / CSS basics from scratch",
    levels: [
      {
        label: "HTML Basics",
        key: "html-basics",
      },
      {
        label: "CSS Basics",
        key: "css-basics",
      },
    ],
    illust: "HTML_CSS",
    label: "NEW",
    status: "public",
  },
  {
    title: "HTML / CSS Clone Exercises",
    key: "html-css-clone-exercises",
    excerpt:
      "Let's clone the Instagram responsive website with only HTML and CSS.",
    levels: [
      {
        label: "Instagram Clone",
        key: "instagram",
      },
      {
        label: "Facebook Clone",
        key: "facebook",
      },
    ],
    illust: "InstaClone",
    label: "NEW",
    status: "public",
  },
  {
    title: "Modern JavaScript Basics",
    key: "modern-javascript-basics",
    excerpt: "Learn modern JavaScript basics from scratch",
    levels: [
      {
        label: "JavaScript Basics",
        key: "javascript-basics",
      },
    ],
    illust: "",
    label: "",
    status: "public",
  },
  {
    title: "React Basics - Components",
    key: "react-basics-components",
    excerpt:
      "Learn basic Component concept and usage of the React JS from scratch",
    levels: [
      {
        label: "React Component",
        key: "react-component",
      },
      {
        label: "CSS-in-JS",
        key: "css-in-js",
      },
      {
        label: "My Button Component",
        key: "my-button-component",
      },
    ],
    illust: "ReactComponents",
    label: "NEW",
    status: "public",
  },
  {
    title: "React Basics - HTML-CSS Clone Practices",
    key: "react-basics-clone-practices",
    excerpt: "",
    levels: [
      {
        label: "Instagram - CSS to React Components ",
        key: "instagram-components",
      },
    ],
    illust: "",
    label: "",
    status: "public",
  },
  {
    title: "Theming - Instagram Website",
    key: "theming",
    excerpt: "Add a Theme System to the Instagram website",
    levels: [
      {
        label: "Theming With CSS Variables",
        key: "theming-with-css-variables",
      },
    ],
    illust: "",
    label: "",
    status: "public",
  },
  {
    title: "Framer for a Static Design System",
    key: "framer-static-design-system",
    excerpt: "Migrate Instagram React Components to the Framer Web",
    levels: [
      {
        label: "React Components on Framer",
        key: "react-components-on-framer",
      },
      {
        label: "Instagram Design System",
        key: "instagram-design-system",
      },
    ],
    illust: "",
    label: "",
    status: "public",
  },
  {
    title: "Framer Web for a Interactive Design System",
    key: "",
    excerpt: "Add Interactive animation to the Instagram Components",
    levels: [],
    illust: "",
    label: "",
    status: "draft",
  },
  {
    title: "Fast Prototyping with Framer",
    key: "fast-prototyping",
    excerpt: "Learn Framer for fast prototyping from the starting points.",
    levels: [
      {
        label: "No-code: Magic Motion and Framer Packages",
        key: "no-code",
      },
      {
        label: "Lv.1-1: Fast Prototyping with Imperative Way",
        key: "1-1",
      },
      {
        label: "Lv.1-2: More Responses & Triggers",
        key: "1-2",
      },
      {
        label: "Lv.2: JavaScript Basics (Condition & Variables)",
        key: "2",
      },
    ],
    illust: "HTML_CSS",
    label: "",
    status: "hidden",
  },
  {
    title: "Declarative Prototyping With Framer",
    key: "declarative-prototyping",
    excerpt: `Do declarative prototyping for super productivity with the full
    power of Framer`,
    levels: [
      {
        label: "Lv.3-1: Controlling States (Override + Data)",
        key: "3-1",
      },
    ],
    illust: "Cloud",
    label: "",
    status: "hidden",
  },
  {
    title: "Unsorted",
    key: "unsorted",
    excerpt: "",
    levels: [
      {
        label: "Toolbox",
        key: "toolbox",
      },
    ],
    illust: "HTML_CSS",
    label: "",
    status: "hidden",
  },
]

export const COURSES_KO = [
  {
    title: "HTML / CSS 기초",
    key: "modern-html-css",
    excerpt:
      "아무 배경 지식 없이, 현대 웹 기술에서 실제로 쓰이는 HTML 와 CSS를 기초 부터 배워 봅니다",
    levels: [
      {
        label: "HTML Basics",
        key: "html-basics",
      },
      {
        label: "CSS Basics",
        key: "css-basics",
      },
    ],
    illust: "HTML_CSS",
    label: "NEW",
    status: "public",
  },
  {
    title: "HTML / CSS 인스타그램 클론",
    key: "html-css-clone-exercises",
    excerpt:
      "HTML과 CSS만을 이용해, 인스타그램 반응형 웹사이트를 만들어 봅니다",
    levels: [
      {
        label: "Instagram Clone",
        key: "instagram",
      },
      {
        label: "Facebook Clone",
        key: "facebook",
      },
    ],
    illust: "InstaClone",
    label: "NEW",
    status: "public",
  },
  {
    title: "React 기초 - Components",
    key: "",
    excerpt: "(코스 준비 중입니다)",
    levels: [],
    illust: "ReactComponents",
    label: "",
    status: "draft",
  },
  {
    title: "React Components - 인스타그램 클론",
    key: "",
    excerpt: "(코스 준비 중입니다)",
    levels: [],
    illust: "InstaCloneReactComponents",
    label: "",
    status: "hidden",
  },
  {
    title: "Fast Prototyping with Framer",
    key: "fast-prototyping",
    excerpt: "Learn Framer for fast prototyping from the starting points.",
    levels: [
      {
        label: "No-code: Magic Motion and Framer Packages",
        key: "no-code",
      },
      {
        label: "Lv.1-1: Fast Prototyping with Imperative Way",
        key: "1-1",
      },
      {
        label: "Lv.1-2: More Responses & Triggers",
        key: "1-2",
      },
      {
        label: "Lv.2: JavaScript Basics (Condition & Variables)",
        key: "2",
      },
    ],
    illust: "HTML_CSS",
    label: "",
    status: "hidden",
  },
  {
    title: "Declarative Prototyping With Framer",
    key: "declarative-prototyping",
    excerpt: `Do declarative prototyping for super productivity with the full
    power of Framer`,
    levels: [
      {
        label: "Lv.3-1: Controlling States (Override + Data)",
        key: "3-1",
      },
    ],
    illust: "Cloud",
    label: "",
    status: "hidden",
  },
  {
    title: "Unsorted",
    key: "unsorted",
    excerpt: "",
    levels: [
      {
        label: "Toolbox",
        key: "toolbox",
      },
    ],
    illust: "HTML_CSS",
    label: "",
    status: "hidden",
  },
]

/**
 * UI
 */
export const HEADER_LOGO_HEIGHT = 30

export const MODAL = {
  SIGN_IN: "sign-in",
  BECOME_A_MEMBER: "become-a-member",
  PAUSE_SUBSCRIPTION: "pause-subscription",
  RESUME_SUBSCRIPTION: "resume-subscription",
  CANCEL_SUBSCRIPTION: "cancel-subscription",
  UPDATE_NAME: "update-name",
  UPDATE_PAYMENT: "update-payment",
  WAIT_LIST: "waitlist-subscription",
}

export const NAVIGATE = {
  COURSE: "COURSE",
  LEVEL: "LEVEL",
  POST: "POST",
}

export const VIDEO_RATIO = 1280 / 2048 /* Player ratio: 100 / (1280 / 2048) */

/**
 * Video Explorer
 */
export const CANVAS_DEFAULT_FPS = 3
export const CANVAS_RESOLUTION = 1080

export const SECTION_SUMMARY_CLASSNAME = "section-summary"
export const SECTION_DETAIL_STEPS_CLASSNAME = "section-detail-steps"
export const FOCUS_POINT = 0.2
export const SCROLL_START = isClient && window.innerHeight * FOCUS_POINT
export const veScrollRevealDelay = SCROLL_START + 200

/**
 * Admin (Temp)
 */
export const tempPassword = "0219"
