import * as React from "react"
import { styled } from "styletron-react"
import { VIDEO_RATIO } from "../../../../constants"
import { DesktopView } from "../../../../utils"

export function SectionSummaryVideo({ src }) {
  const ResponsiveVideoWrap = styled("div", ({ $theme, $kind }) => {
    return {
      position: "relative",
      paddingTop: `${VIDEO_RATIO * 100}%`,
    }
  })

  const Video = styled("video", ({ $theme, $kind }) => {
    return {
      position: "absolute",
      top: "0px",
      left: "0px",
      pointerEvents: "none",
    }
  })
  return (
    <DesktopView>
      <ResponsiveVideoWrap>
        <Video width="100%" muted autoPlay loop>
          <source src={src} type="video/mp4" />
          Your browser does not support HTML5 video.
        </Video>
      </ResponsiveVideoWrap>
    </DesktopView>
  )
}

SectionSummaryVideo.defaultProps = {}
