import * as React from "react"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import { stringKnife, sizeBg } from "../../../../utils"
import { useContext } from "react"
import { SectionDetailStepsContext } from "../../../../store"

export function StepLabel({ children }) {
  const theme = useTheme()
  const ctx: any = useContext(SectionDetailStepsContext) // States from Form
  const active = ctx.$currentStep + 1 == children.split(" ")[1] // get step number from the text (ex. Step 10 -> 10)

  return (
    <>
      <System.Spacing height="scale800" backgroundColor={sizeBg} />
      <System.LabelSmall
        overrides={{
          Block: {
            color: active ? theme.colors.primary : theme.colors.contentPrimary,
            fontWeight: 800,
          },
        }}
      >
        {children}
      </System.LabelSmall>
      <System.Spacing height="scale700" backgroundColor={sizeBg} />
    </>
  )
}

StepLabel.defaultProps = {}
