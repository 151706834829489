import * as React from "react"

type SectionDetailStepsContextProps = {
  Provider: any
  Consumer: any
}

export const SectionDetailStepsContext: SectionDetailStepsContextProps = React.createContext(
  null
)
