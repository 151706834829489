export async function loadFireStore(collectionKey, documentKey) {
  const firebase = await import("firebase/app")
  await import("firebase/firestore")

  try {
    !firebase.apps.length &&
      firebase.initializeApp({
        apiKey: process.env.apiKey,
        projectId: process.env.projectId,
      })
  } catch (error) {
    /*
     * We skip the "already exists" message which is
     * not an actual error when we're hot-reloading.
     */
    if (!/already exists/.test(error.message)) {
      console.error("Firebase initialization error", error.stack)
    }
  }
  const res: any = await firebase.firestore()
  if (documentKey) return res.collection(collectionKey).doc(documentKey)
  else if (collectionKey) return res.collection(collectionKey)
  else return res
}
