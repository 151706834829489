import { useStaticQuery, graphql } from "gatsby"

export const useFirstPosts = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { frontmatter: { order: { eq: 0 } } }) {
          edges {
            node {
              id
              frontmatter {
                title
                order
                category
                course
              }
              fields {
                originalPath
                pathname
              }
            }
          }
        }
      }
    `
  )
  return allMdx
}
